@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

#teacher-groups-page,
#student-groups-page {
  @extend %flex-col;
  align-items: stretch;
  padding: 0 2rem 8rem 2rem;

  section#groups-content {
    @extend %flex-row;
    justify-content: center;
  }

  a.add-members-btn,
  button.move-here-btn {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;

    i {
      display: inline-block;
      margin-right: 0.5rem;
    }

    i,
    span {
      vertical-align: middle;
    }
  }

  a.add-members-btn {
    @extend %default-btn;
    display: block;
    background-color: #e5e5e5;
    color: #666666;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
      color: #494949;
      background-color: #d4d4d4;
    }

    i {
      display: inline-block;
      margin-right: 0.5rem;
    }

    i,
    span {
      vertical-align: middle;
    }
  }

  #group-container,
  #group-list {
    ul {
      @extend %default-list;
    }

    .member-entry {
      @extend %flex-row;
      align-items: baseline;

      span.entry-name {
        vertical-align: middle;

        span.leader-icon {
          margin-right: 0.5rem;
          color: #d57e01;
          user-select: none;
          line-height: normal;

          i {
            font-size: 20px;
          }
        }

        span {
          vertical-align: middle;
        }
      }
    }
  }
}

span.group-size-indicator {
  margin-right: 0.5rem;
  user-select: none;
  pointer-events: none;

  i,
  span {
    vertical-align: middle;
  }

  i {
    font-size: 20px;
    margin-right: 0.25rem;
  }
}

#teacher-groups-page {
  #group-list {
    flex-basis: 100%;
    max-width: 480px;

    #ctrls-bar {
      margin-bottom: 1rem;

      button {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .Toggle {
        margin-top: 1rem;
      }
    }

    .member-entry {
      padding: 0.5rem 1rem;
      font-weight: bold;

      .avatar {
        margin: 0 1rem;
      }

      &:first-child {
        margin-top: 0.5rem;
      }

      .peer-dropdown {
        margin-left: 0.5rem;

        button {
          padding: 0 !important;
        }
      }
    }

    #group-add-loading-placeholder {
      @extend %flex-row-jc-ac;
      @include pres.panel-padding-radius(0.5rem 1rem, 0.25rem);
      box-shadow: 0 3px 6px 4px #0000002d;
      height: 3rem;
      font-weight: bold;
      color: rgb(63, 63, 63);
      margin-bottom: 0.5rem;

      span {
        margin-left: 0.5rem;
      }
    }

    #moving-heading {
      margin-bottom: 0.5rem;

      button,
      h2 {
        vertical-align: middle;
      }

      button {
        margin-right: 0.5rem;
      }

      h2 {
        display: inline-block;
        margin: 0;
      }
    }

    p#selected-group {
      span.name {
        margin-right: 1rem;
      }

      span.groups {
        b.old-group {
          color: #d12323;
        }

        i {
          margin: 0 0.5rem;
          vertical-align: middle;
          font-size: 20px;
        }

        b.new-group {
          color: #38b934;
        }
      }
    }

    button#confirm-move-btn {
      margin-bottom: 1rem;
    }
  }

  #student-group-membership-table {
    .without-group {
      color: white;
      background-color: vars.$primary-color-2;
      border-radius: 10rem;
      padding: 0.25rem 0.5rem;
    }
  }
}

#add-members-interface {
  flex-basis: 100%;
  padding: 2rem 0;
  max-width: 360px;

  h1 {
    font-size: vars.$font-size-sm;

    &,
    & + p {
      text-align: center;
    }
  }

  #selected-heading {
    margin-bottom: 0.5rem;

    button,
    h2 {
      vertical-align: middle;
    }

    button {
      margin-right: 0.5rem;
    }

    h2 {
      display: inline-block;
      margin: 0;
      font-size: vars.$font-size-xs;
    }
  }

  p#selected-names {
    min-height: 24px;
  }

  #confirm-btn {
    margin-bottom: 1rem;
  }

  #user-list {
    @extend %flex-col;
    align-items: stretch;

    .user-entry {
      @extend %flex-row;
      position: relative;
      margin-bottom: 0.5rem;
      height: 4.5rem;

      input[type='checkbox'] {
        @extend %absolute-and-fill-space;
        opacity: 0;
        pointer-events: none;

        &:focus-visible + label {
          @extend %focus-visible-style;
        }

        &:checked {
          + label {
            border-color: vars.$primary-color-2;

            i.check-indicator {
              opacity: 1;
            }
          }
        }
      }

      label {
        @extend %flex-row-ac;
        flex-grow: 1;
        color: vars.$primary-text-color;
        border: 2px solid transparent;
        padding: 0.5rem 1.5rem;
        background-color: white;
        border-radius: 10rem;
        cursor: pointer;
        margin: 0;
        user-select: none;
        height: 100%;
        font-weight: bold;

        span.avatar-wrapper {
          margin-right: 1.5rem;
        }

        span.name {
          flex-grow: 1;
        }

        i.check-indicator {
          color: vars.$primary-color-2;
          font-size: vars.$font-size-sm;
          opacity: 0;
        }

        &:hover {
          background-color: rgb(248, 248, 248);
          border-color: rgb(179, 179, 179);
        }
      }
    }
  }
}

#student-groups-page {
  .mininav,
  #my-group,
  #student-list,
  #group-list {
    flex-basis: 100%;
  }

  #my-group {
    max-width: 400px;

    #heading-bar {
      @extend %flex-row-ac;
      justify-content: space-between;
      margin: 1rem 0;

      button#group-name-edit {
        @extend %default-btn;
        border-radius: 4px;

        &:hover {
          background-color: rgb(221, 221, 221);
        }

        h2 {
          margin: 0;

          i {
            font-size: vars.$font-size-xs;
            margin-left: 0.25rem;
          }
        }
      }
    }

    #group-container {
      .member-entry {
        background-color: white;
        padding: 1rem 1.5rem;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;

        .avatar {
          margin-right: 1.5rem;
        }

        .badge {
          background-color: rgb(238, 238, 238);
          color: rgb(100, 100, 100);
          margin-left: 0.5rem;
          text-transform: uppercase;
          font-size: 12px;
          padding: 0.25rem;
          border-radius: 4px;
          letter-spacing: 1px;
        }

        .peer-dropdown {
          margin-left: 0.5rem;
        }

        .request-ctrls {
          @extend %flex-row-jc;
          margin-top: 0.5rem;

          a,
          button {
            margin: 0 0.25rem;
          }
        }
      }

      .pending {
        .avatar,
        .name {
          opacity: 0.5;
        }
      }
    }
  }

  #no-group {
    h1 {
      margin-bottom: 2rem;
    }

    button,
    a {
      display: block;
      margin-bottom: 1rem;
      width: 100%;
      text-transform: none;
    }

    p {
      text-align: center;
    }
  }

  #student-list,
  #group-list {
    max-width: 640px;

    #list-ctrls {
      margin-bottom: 1rem;
    }

    #list-wrapper {
      .student-entry,
      .group-entry {
        @extend %flex-row-ac;
        justify-content: space-between;
        background-color: white;
        padding: 1rem;
        border-radius: 10rem;
        margin-bottom: 0.5rem;

        button {
          text-transform: none;
          letter-spacing: 1px;
          word-spacing: 0;
          min-width: 12rem;
        }

        button.sending-wait-btn,
        button.invite-sent-btn {
          opacity: 1;
          cursor: auto;
        }

        button.invite-sent-btn {
          background-color: #58cf7c;
          animation: bounceIn 0.5s;

          span {
            margin-right: 0.25rem;
          }

          i {
            font-size: 20px;
            vertical-align: top;
            margin-right: -0.5rem;
          }
        }
      }
    }
  }

  #student-list {
    #list-ctrls {
      @extend %flex-row-ac;
      justify-content: space-between;
      flex-wrap: wrap;

      .searchbar {
        max-width: 312px;
      }

      input#showSoloOnly {
        margin: 0 0.5rem;
      }
    }

    #list-wrapper {
      .student-entry {
        .left-wrapper {
          @extend %flex-row-ac;

          .avatar {
            margin: 0 1.5rem 0 0.5rem;
          }

          .entry-details {
            font-weight: bold;

            .in-group {
              color: #288543;
            }

            .no-group {
              color: vars.$pale-text-color;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  #group-list {
    .group-entry {
      align-items: stretch !important;

      .details {
        @extend %flex-col;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0 0.5rem;

        .name {
          padding: 0 0.5rem;
          margin-right: 0.5rem;
        }

        .peer-dropdown {
          align-self: stretch;

          .group-size-indicator {
            margin-right: 0.25rem;
            .names-preview {
              margin-left: 0.5rem;
              letter-spacing: 0 !important;
            }
          }

          ul {
            list-style: none;
            padding: 0 1rem;
          }
        }
      }
    }
  }
}

#group-upload-menu,
#auto-group-menu {
  @extend %flex-col-ac;
  align-self: stretch;

  p#explainer {
    align-self: stretch;
    text-align: left;
    max-width: 320px;
  }

  p,
  label,
  textarea,
  input[type='number'] {
    margin: 0 0 1rem 0 !important;
  }

  .modal-tabs {
    @extend %flex-row;
    justify-content: space-evenly;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
      padding: 0.5rem 1.6rem;
      margin: 0 0.5rem;
      border-bottom: 2px solid vars.$border-soft-color;
    }

    span.selected {
      border-color: vars.$primary-color-2;
    }
  }

  .modal-tab {
    @extend %flex-col-ac;
    align-self: stretch;
  }

  label {
    cursor: pointer;
    position: relative;

    input[type='file'] {
      @extend %absolute-and-fill-space;
      @include pres.width-height(100%);
      opacity: 0;
      overflow: hidden;
      z-index: -1;
    }
  }

  input[type='number'] {
    max-width: 8rem;
    text-align: center;
  }

  table#csv-example {
    font-size: 12px;
    margin-bottom: 1rem;
  }
}

#assign-group-modal-content,
#copy-past-group-modal-content {
  @extend %flex-col-ac;

  label {
    display: block;
    margin: 0 0 1rem 0 !important;
  }

  select {
    margin-bottom: 4rem;
    max-width: 320px;
  }
}

#group-sync-menu {
  .input-wrapper {
    @extend %flex-row-jc-ac;
  }
}

#group-permissions-menu {
  .permission {
    @extend %flex-row-ac;
    margin-bottom: 1rem;
    gap: 1px;
  }

  label {
    margin: 0 !important;
  }
}

@include pres.bp-tablet-sm {
  #student-groups-page > section#groups-content {
    #student-list {
      #list-ctrls {
        flex-direction: column;
        align-items: stretch;

        .searchbar {
          max-width: 100% !important;
          margin-bottom: 1rem;
        }

        span {
          text-align: center;
        }
      }
    }

    #group-list,
    #student-list {
      .student-entry,
      .group-entry {
        flex-wrap: wrap;
        border-radius: 1rem;

        .left-wrapper,
        .details,
        button {
          flex: 0 0 100%;
        }

        .left-wrapper,
        .details {
          justify-content: center;
          margin-bottom: 1rem;

          .entry-details {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

@include pres.bp-mobile-lg {
  #teacher-groups-page,
  #student-groups-page {
    padding: 0 1rem 8rem 1rem;
  }
}
