@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.calendar {
  border: 1px solid #e5e5e5;
  border-radius: 1rem 1rem 0 0;

  .ctrls {
    @extend %flex-row-ac;
    justify-content: space-between;
    padding: 1rem 3rem;
    width: 100;
  }

  table {
    border-collapse: collapse;
    color: #6e6e6e;

    th,
    td {
      @include pres.width-height(3rem);
      padding: 0;
      font-weight: normal;
      text-align: center;
      user-select: none;
    }

    td {
      cursor: pointer;

      &:hover {
        color: black;
        background-color: rgba($color: #e5e5e5, $alpha: 0.3);
        box-shadow: inset 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
      }
    }

    td.past,
    td.future {
      opacity: 0.3;
    }

    td.today {
      box-shadow: inset 0 0 0 0.25rem #6e6e6eaf;
    }

    thead tr th {
      border: none;
    }
  }
}
