@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;
@use 'table_menus/ExpandedRow';
@use 'details/details';

.roster-container,
.permission-container,
.course-section-container {
  @extend %flex-col;
  align-content: stretch;
  flex-grow: 1;
  padding: 1rem;
  min-width: 0;
  color: vars.$navbar-color;

  section {
    @extend %flex-row-jc-ac;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  section.assignment-select-wrapper {
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 2rem !important;

    label {
      font-size: vars.$font-size-sm;
      font-weight: bold;
      margin-right: 1rem;
    }

    select#assignment-select {
      max-width: 90vw;
    }
  }

  .instructor-reviewed-icon {
    position: absolute;
    color: vars.$link-text-color;
  }
}

#report-page {
  @extend %flex-row-jc;
  padding: 2rem;

  #judgement-interface {
    flex-grow: 1;
    max-width: 1000px;
  }

  .decision-ctrls {
    @extend %flex-col;
    align-items: flex-start;

    .decision-btn {
      @extend %default-btn;
      @include pres.panel-padding-radius(1rem, 0.5rem);
      margin-bottom: 0.5rem;
      width: 360px;
      text-align: left;
      vertical-align: middle;

      i {
        vertical-align: middle;
        margin-right: 0.5rem;
      }

      &:hover {
        background-color: rgb(240, 240, 240);
        box-shadow: 0 0 0 4px lighten(vars.$primary-color-2, 20%);
      }
    }
  }

  #reported-submission-wrapper {
    @extend %flex-row;
    align-items: stretch;
    flex-grow: 1;

    .review-submission-window {
      flex-grow: 1;
      max-width: 1000px;
      height: 1000px;
    }
  }

  #reported-review-wrapper {
    @extend %flex-col;
    align-items: flex-start;
  }
}

@include pres.bp-laptop-sm {
  .roster-container {
    padding: 3rem 2rem 10rem 2rem;
  }
}

@include pres.bp-tablet-sm {
  .roster-container {
    padding: 2rem 1rem 10rem 1rem;
  }

  section.assignment-select-wrapper {
    justify-content: center !important;
    margin-bottom: 2rem;
  }
}

.permission-container {
  padding: 1rem;
  align-items: center;
  .tabs {
    max-width: 1200px;
  }
}

#ctrls-bar {
  @extend %flex-row;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap:0.2rem;
}

.course-section-entries {
  ul {
    @extend %default-list;
  }
  .member-entry {
    @extend %flex-row;
    align-items: baseline;
    padding: 0.5rem 1rem;

    span.entry-name {
      vertical-align: middle;
      font-weight: bold;
      margin: 0.5rem;

      span.leader-icon {
        margin-right: 0.5rem;
        color: #d57e01;
        user-select: none;
        line-height: normal;

        i {
          font-size: 20px;
        }
      }

      span {
        vertical-align: middle;
      }
    }
  }
  .add-members-btn {
    @extend %default-btn;
    display: block;
    background-color: #e5e5e5;
    color: #666666;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;

    &:hover {
      color: #494949;
      background-color: #d4d4d4;
    }
    i,
    span {
      vertical-align: middle;
    }
  }
}

.course-section-add-members {
  #section-add-members {
    flex-basis: 100%;
    padding: 2rem 1rem;

    h1 {
      font-size: vars.$font-size-sm;

      &,
      & + p {
        text-align: center;
      }
    }

    #section-selected-heading {
      margin-bottom: 0.5rem;

      button,
      h2 {
        vertical-align: middle;
      }

      button {
        margin-right: 0.5rem;
      }

      h2 {
        display: inline-block;
        margin: 0;
        font-size: vars.$font-size-xs;
      }
    }

    #section-selected-names {
      min-height: 24px;
    }

    #section-confirm-btn {
      margin-bottom: 1rem;
    }

    #section-user-list {
      @extend %flex-col;
      align-items: stretch;

      .user-entry {
        @extend %flex-row;
        position: relative;
        margin-bottom: 0.5rem;
        height: 4.5rem;

        input[type='checkbox'] {
          @extend %absolute-and-fill-space;
          opacity: 0;
          pointer-events: none;

          &:focus-visible + label {
            @extend %focus-visible-style;
          }

          &:checked {
            + label {
              border-color: vars.$primary-color-2;

              i.check-indicator {
                opacity: 1;
              }
            }
          }
        }

        label {
          @extend %flex-row-ac;
          flex-grow: 1;
          color: vars.$primary-text-color;
          border: 2px solid transparent;
          padding: 0.5rem 1.5rem;
          background-color: white;
          border-radius: 10rem;
          cursor: pointer;
          margin: 0;
          user-select: none;
          height: 100%;
          font-weight: bold;

          span.avatar-wrapper {
            margin-right: 1.5rem;
          }

          span.name {
            flex-grow: 1;
          }

          i.check-indicator {
            color: vars.$primary-color-2;
            font-size: vars.$font-size-sm;
            opacity: 0;
          }

          &:hover {
            background-color: rgb(248, 248, 248);
            border-color: rgb(179, 179, 179);
          }
        }
      }
    }
  }
}

.section-unavailable {
  margin-top: 3rem;
}
