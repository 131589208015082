@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

.submission-type-menu {
  @extend %flex-row-jc-ac;

  .submission-type-btn-wrapper {
    @include pres.width-height(200px, 320px);
    position: relative;
    margin: 0.5rem;

    input[type='checkbox'] {
      @extend %absolute-and-fill-space;
      opacity: 0;
      cursor: pointer;

      &:focus-visible + label {
        @extend %focus-visible-style;
      }

      &:checked {
        + label {
          border-color: #38b934;

          span.type::before {
            content: 'check';
            font-family: 'Material Icons';
            color: #38b934;
            font-size: vars.$font-size-sm;
            position: absolute;
            left: -1.5ch;
          }
        }
      }

      &:hover {
        + label {
          box-shadow: 0 0 0 2px lighten(#d12323, 20%);
          border-color: lighten(#d12323, 20%);
        }
      }

      &:checked:hover {
        + label {
          box-shadow: 0 0 0 2px lighten(#38b934, 20%);
          border-color: lighten(#38b934, 20%);
        }
      }
    }

    label {
      @extend %flex-col-ac;
      color: vars.$primary-text-color;
      border: 2px solid #d12323;
      border-radius: 2rem;
      padding: 2rem 1rem;
      background-color: transparent;
      margin: 0;
      user-select: none;
      height: 100%;

      i {
        font-size: 50px;
        margin-bottom: 1rem;
      }

      span.type {
        position: relative;
        margin-bottom: 1rem;

        &::before {
          content: 'close';
          font-family: 'Material Icons';
          color: #d12323;
          font-size: vars.$font-size-sm;
          position: absolute;
          left: -1.5ch;
        }
      }

      .type-description {
        font-size: vars.$font-size-xs;
        font-weight: normal;

        p:last-child {
          margin: 0;
        }
      }

      &:hover {
        background-color: rgba($color: #c3c3c3, $alpha: 0.1);
      }
    }
  }
}

select#num-reviews-select {
  align-self: flex-start;
}

.feedback-interface {
  .title-button-wrapper,
  .rating-wrapper {
    label,
    h3,
    p {
      display: inline;
      margin-right: 1rem;
    }
  }

  button {
    span.icon {
      display: none;
    }
  }
}

.grade-weight-widget-container {
  @extend %flex-row;

  figure {
    @extend %flex-col-ac;
    margin: 2rem;

    figcaption {
      margin-top: 1rem;
      font-size: vars.$font-size-sm;
      font-weight: bold;
      text-align: center;

      span.weight-total {
        display: inline-block;
        width: 5rem;

        i {
          vertical-align: -10%;
        }
      }

      span.low {
        color: #d12323;
      }

      span.done {
        color: #38b934;
      }
    }
  }

  table {
    margin-top: 2rem;
    height: 256px;

    td {
      padding: 1rem 0.5rem;
    }

    label {
      margin: 0;
      vertical-align: middle;
    }

    input[type='number'] {
      width: 5rem;
    }
  }
}

.assignment-row form.wizard {
  fieldset {
    flex-direction: row !important;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    p {
      font-weight: normal;
      flex-basis: 100%;
      margin: 1rem 0 0 0;
    }
  }
}

.sub-section {
  padding: 1rem 0 0 2rem;
}

.stats-interface {
  @extend %flex-row;
  flex-wrap: wrap;
  padding-left: 2rem;

  span {
    margin-right: 2rem;
  }
}

.calendar-interface {
  @extend %flex-row;
  flex-wrap: wrap;
  align-items: flex-start;

  label {
    margin-right: 1rem;
  }

  input[type='date'],
  input[type='time'] {
    border-radius: 4px;
    border-width: 1px;
  }

  .date-list {
    @extend %flex-col;
    padding-left: 4rem;

    p#timezone-prompt {
      color: vars.$pale-text-color;
    }

    input {
      margin-bottom: 1rem;
    }

    .phase-time-settings {
      .phase-inputs-wrapper {
        @extend %flex-row;
        justify-content: space-between;

        div:first-child {
          margin-right: 2rem;
        }
      }

      label {
        font-size: vars.$font-size-xs !important;
        font-weight: normal !important;
        margin-right: 0.5rem;
      }

      input[type='number'] {
        text-align: center;
        margin: 0 1ch;
        width: 5rem;
      }

      input[type='date'] {
        width: 16ch;
        cursor: pointer;
      }

      .key-color-indicator {
        @include pres.width-height(2rem);
        display: inline-block;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}

ul#rubric-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    padding-left: 1rem;

    &::before {
      font-family: 'Material Icons';
      padding-right: 0.5rem;
    }
  }

  li.comment::before {
    content: 'chat';
  }

  li.rating::before {
    content: 'format_list_numbered';
  }
}

@include pres.bp-laptop-md {
  .calendar-interface {
    flex-direction: column !important;
    align-items: center !important;

    .date-list {
      padding: 2rem 0 0 0 !important;
    }
  }
}

@include pres.bp-laptop-sm {
  .sub-section {
    padding: 1rem 0 0 2rem;
  }

  .grade-weight-widget-container {
    flex-wrap: wrap;

    figure > .pie-chart {
      width: 192px !important;
      height: 192px !important;
    }

    table.formatting-table {
      margin: 0 !important;
    }
  }
}

@include pres.bp-tablet-lg {
  .feedback-interface {
    button {
      span.text {
        display: none;
      }

      span.icon {
        display: inline-block !important;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  .sub-section {
    padding: 0 !important;
  }

  .calendar-interface {
    th,
    td {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }

  .grade-weight-widget-container {
    justify-content: center;
  }
}
