@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

#teacher-dashboard {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  p {
    font-size: vars.$font-size-xxs;
    margin-bottom: 0.5rem;
    color: vars.$navbar-color;
  }

  .card {
    @include pres.panel-padding-radius(1rem, 1rem);
    margin: 0.5rem;

    .heading {
      @extend %flex-row;
      justify-content: space-between;
    }
  }

  .dashboard-main {
    .card {
      width: 512px;
    }

    #actions-card,
    #activity-card {
      h2 {
        font-size: vars.$font-size-xs;
      }
    }

    #actions-card {
      @extend %flex-col;
      overflow-x: auto;

      svg {
        height: 24px;
        width: auto;
        align-self: center;
        margin: 0.5rem 0;
      }

      #action-ctrls-wrapper {
        position: relative;
        height: 3rem;

        .action-wrapper {
          position: absolute;
          width: 72px;
          top: 0;
          text-align: center;
          color: vars.$pale-text-color;
          font-size: vars.$font-size-xxs;

          a,
          button {
            padding: 0.25rem 0.5rem !important;
            letter-spacing: 0 !important;
          }
        }
      }
    }

    #activity-card {
      .ctrls {
        font-size: vars.$font-size-xxs;
      }
    }

    #template-disclaimer {
      width: 512px;
      margin: 0.5rem;
    }
  }

  .dashboard-side {
    .card {
      width: 256px;
    }

    #timeline-card {
      .heading-wrapper {
        position: relative;

        h2 {
          font-size: vars.$font-size-xs;
          line-height: vars.$font-size-xs;
          text-align: center;
          margin-bottom: 0.5rem;
        }

        p#deadline-time {
          text-align: center;
          color: vars.$pale-text-color;
        }

        button#calendar-btn {
          position: absolute;
          top: -0.5rem;
          right: 0;
          padding: 0.25rem;

          i {
            color: vars.$pale-text-color;
          }
        }
      }

      .timeline-entry {
        @extend %flex-row-ac;
        padding: 1rem 0;

        .date {
          width: 2rem;
          text-align: center;
          flex-shrink: 0;

          .weekday {
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: vars.$font-size-xxs;
            line-height: vars.$font-size-xxs;
            margin-bottom: 0.25rem;
          }

          .day {
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 1px;
          }
        }

        .assignment-phase-icon {
          margin: 0 1rem;
        }

        h3 {
          font-size: vars.$font-size-xxs;
          margin: 0;
          font-weight: normal;
        }

        &.past {
          opacity: 0.55;

          .assignment-phase-icon {
            opacity: 0.75;
          }
        }

        &.current {
          .date,
          h3 {
            font-weight: bold;
          }
        }
      }

      .timeline-entry.past + .timeline-entry.upcoming {
        border-top: 2px solid vars.$border-soft-color;
      }
    }
  }
}

.activity-tooltip {
  padding: 0.5rem;

  h4 {
    text-decoration: underline;
  }

  h4,
  p,
  th,
  td,
  .progress-number-sm {
    font-size: vars.$font-size-xxs !important;
  }

  p#no-activity {
    color: vars.$pale-text-color;
    text-align: center;
    margin: 1rem 0;
    font-weight: normal;
  }

  table {
    @extend %table-no-borders;

    td.icon-cell {
      padding: 0;
    }

    th {
      text-align: left;
    }

    td {
      padding: 0.25rem;
      font-weight: normal;
    }
  }
}

.activity-graph {
  .filter-tab {
    legend {
      margin: 0 0.5rem 0 0 !important;
      font-size: vars.$font-size-xxs !important;

      b {
        font-weight: normal !important;
      }
    }

    .filter-btn {
      margin: 0.125rem;
      font-size: vars.$font-size-xxs !important;

      input[type='checkbox'] {
        & + label {
          color: vars.$navbar-color !important;
          background-color: #d6d6d6;
          padding: 0.125rem 0.5rem;

          span {
            &::before {
              @include pres.width-height(1rem);
              content: '';
              display: inline-block;
              border: 1px solid white;
              border-radius: 2px;
              margin-right: 0.5rem;
              vertical-align: middle;
            }
          }
        }

        &:hover + label {
          background-color: darken(#d6d6d6, 5%);
          border-color: darken(#d6d6d6, 5%);
        }
      }

      input[type='checkbox']:checked {
        & + label {
          background-color: #97d5ff;
          border-color: #97d5ff;
        }

        &:hover + label {
          background-color: darken(#97d5ff, 5%);
        }
      }

      &.btn-submission label span::before {
        background-color: #7878f1;
      }
      &.btn-review label span::before {
        background-color: #e676e3;
      }
      &.btn-feedback label span::before {
        background-color: #e4c445;
      }
      &.btn-evaluate label span::before {
        background-color: #55c92d;
      }
    }
  }
}

@include pres.bp-laptop-sm {
  #teacher-dashboard {
    margin-bottom: 6rem;
    align-items: flex-start;
  }
}

@include pres.bp-tablet-lg {
  #teacher-dashboard {
    .dashboard-main {
      .card {
        width: 416px;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  #teacher-dashboard {
    .dashboard-main {
      .card {
        width: calc(100vw - 1rem);
      }

      #details-card {
        .heading {
          flex-direction: column;

          span.options-wrapper {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
