@use '../../../../styles/presets';
@use '../../../../styles/variables' as vars;

@mixin bg-color-w-lighten-hover($bg-color) {
  background-color: $bg-color;

  &:hover {
    background-color: lighten($bg-color, 10%);
  }
}

%peer-button {
  @extend %default-btn;
  @extend %text-all-caps;
  @include bg-color-w-lighten-hover(vars.$primary-text-color);
  display: inline-block;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  color: white;
  font-size: vars.$font-size-xxs;
  text-align: center;
  line-height: 1.5 !important;
  user-select: none;
  box-shadow: none !important;
  transition: background-color 0.2s;
}

.peer-button {
  @extend %peer-button;

  &:disabled {
    opacity: 0.2;
    &:hover {
      cursor: not-allowed;
    }
  }

  &:focus-visible {
    @extend %focus-visible-style;
  }
}

a.peer-button {
  &:hover {
    color: white;
    text-decoration: none;
  }
}

.button-sm {
  @extend %peer-button;
  padding: 0.4rem 1.2rem !important;
  letter-spacing: 2px !important;
}

.button-xs {
  @extend %peer-button;
  font-size: vars.$font-size-xxs !important;
  padding: 0.25rem 0.75rem !important;
  letter-spacing: 1px !important;
}

.button-low {
  @extend %peer-button;
  text-transform: none !important;
}

.button-rad {
  @extend %peer-button;
  border-radius: 10rem !important;
}

.button-secondary {
  @extend %peer-button;
  @include bg-color-w-lighten-hover(lighten(vars.$secondary-color, 20%));
  color: black !important;
}

.button-green {
  @extend %peer-button;
  @include bg-color-w-lighten-hover((#58cf7c));
  color: black !important;
}

button.button-alt,
a.button-alt {
  @extend %peer-button;
  background-color: vars.$white !important;
  color: vars.$primary-text-color;
  border: 1px solid vars.$primary-text-color;
  transition: background-color 0.2s, color 0.2s;

  &:enabled:hover {
    background-color: vars.$primary-text-color !important;
    color: white;
  }
}

a.button-alt {
  &:hover {
    background-color: vars.$primary-text-color !important;
    color: white;
    border-color: white;
  }
}

button.btn-white,
a.btn-white {
  border-color: white !important;
  color: white !important;
  background-color: transparent !important;

  &:enabled:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}

a.btn-white {
  &:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }
}

button.button-link,
a.button-link {
  @extend %default-btn;
  background-color: transparent !important;
  letter-spacing: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;

  &:hover {
    text-decoration: underline;
  }
}

span.tippy-btn-wrapper {
  display: inline-flex;

  * {
    width: 100%;
  }
}
