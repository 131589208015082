// TODO: Make single Progress component (?)
@use '../../../../styles/presets';
@use '../../../../styles/variables' as vars;

.progress-bar-wrapper {
  @extend %flex-row;
  align-items: baseline;

  span {
    font-size: vars.$font-size-xxs;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .progress-bar {
    position: relative;
    height: 0.5rem;
    border-radius: 0.25rem;
    flex-grow: 1;
    overflow: hidden;

    .bar-back {
      @extend %absolute-and-fill-space;
      background-color: #dedede;
    }

    .bar-front {
      background-color: vars.$secondary-color-2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transition: width 0.25s ease;
    }
  }

  .complete {
    .bar-front {
      background-color: vars.$success-color;
    }
  }
}

.progress-ring {
  @extend %flex-row-jc-ac;
  display: inline-flex !important;
  position: relative;
  border-radius: 50%;

  svg {
    background-color: #f9f9f9;
    border-radius: 50%;

    circle {
      transition: stroke-dashoffset 0.5s;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }

  .progress-number-lg,
  .progress-number-sm,
  .progress-number-input-lg,
  .progress-number-input-sm {
    @extend %flex-row-jc-ac;
    @extend %absolute-and-fill-space;
    font-size: vars.$font-size-md;
    font-weight: bold;
    user-select: none;

    input {
      background-color: transparent !important;
      border: none !important;
      padding: 0 !important;
      border-radius: 0 !important;
      text-align: center;
      font-size: inherit;
      font-weight: inherit;
      box-shadow: none !important;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      -moz-appearance: textfield;
    }

    .spinner {
      position: absolute;
      left: 100%;
      margin: 0 1rem;

      button {
        display: block;
        padding: 0 !important;
        font-size: 0;

        i {
          padding: 0 !important;
          font-size: vars.$font-size-md;
        }
      }
    }
  }

  .progress-number-sm {
    font-size: vars.$font-size-xs;
  }
}

.progress-icon {
  @extend %flex-col-jc-ac;
  position: relative;

  svg circle:first-child {
    opacity: 0.25;
  }

  .icon-wrapper {
    @extend %flex-col-jc-ac;
    @extend %absolute-and-fill-space;
    font-size: vars.$font-size-sm;
    color: white;
    text-align: center;

    i {
      vertical-align: -0.5rem;
    }
  }
}
