@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.assignment-phase-icon {
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;

  i {
    width: 100%;
    height: 100%;
    line-height: 150%;
    color: white;
    text-align: center;
    user-select: none;
  }

  &.submit {
    background-color: #7878f1;
  }

  &.review {
    background-color: #e676e3;
  }

  &.feedback {
    background-color: #e4c445;
  }

  &.evaluate {
    background-color: #55c92d;
  }

  &.awaiting {
    background-color: vars.$navbar-color;
  }

  &.complete {
    background-color: #38b934;
  }

  &.reflection{
    background-color: #0096FF;
  }
}
