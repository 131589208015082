@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.peer-dropdown {
  position: relative;
  display: inline-block;

  button.button-mini {
    margin: 0 !important;
    height: 100%;

    &:disabled {
      opacity: 0.25;
    }

    &:hover:disabled {
      background-color: transparent !important;
    }
  }

  .content {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.5rem;
    background-color: vars.$white;
    min-width: 112px;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
    border: 1px solid vars.$border-soft-color;
    z-index: 8000;

    a {
      margin: 0 !important;
      color: vars.$navbar-color;
      font-weight: bold;
      padding: 0.5rem 0.75rem;
      text-decoration: none;
      display: block;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      &:hover {
        background-color: rgb(230, 230, 230) !important;
      }
    }
  }
}
