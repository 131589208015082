@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;
@use './benchmark/benchmark';

.results-row,
.teacher-results-row {
  align-items: flex-start;
  justify-content: center;

  #no-results-container,#export-results-container {
    @extend %flex-col-jc-ac;
    flex-grow: 1;
    align-self: stretch;

    #grades-unavailable-panel, #export-results-panel {
      @extend %flex-col-ac;
      text-align: center;
      flex-grow: 0;

      h1 {
        margin-bottom: 2rem;
      }

      .action-section{
        margin-bottom: 2rem;
      }
    }
  }
}

.grades-card {
  flex-direction: column;

  .grades-container {
    @extend %flex-row-ac;
    justify-content: space-evenly;

    .overall-grade-wrapper {
      padding: 0.5rem;
      text-align: center;
      font-size: vars.$font-size-sm;
      font-weight: bold;
    }

    .sub-grade-wrapper {
      @extend %flex-row-ac;
      padding: 0.5rem;

      .sub-grade {
        @extend %flex-row-ac;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 36px;
          margin-right: 0.25rem;
        }

        i.complete {
          color: vars.$success-color;
        }

        i.incomplete {
          color: vars.$cancel-color;
        }

        .cell {
          @extend %flex-row-ac;
        }

        .cell.with-tooltip {
          cursor: pointer;

          &:hover span.type-name {
            text-shadow: 0px 0px 1px black;
          }
        }

        .progress-ring {
          margin-right: 0.5rem;
        }

        span.weight-calc {
          width: 1.5rem;
          padding-left: 0.2rem;
          margin-left: 0.5rem;
          font-size: vars.$font-size-xxs;
          color: vars.$pale-text-color;
          position: relative;

          &::before {
            content: '×';
            position: absolute;
            right: 100%;
          }
        }

        .explainer {
          p {
            margin-bottom: 0.5rem;
            &:last-child {
              margin: 0;
            }
          }
        }

        .late-penalty {
          display: inline-block;
          font-size: vars.$font-size-xxs;
          font-weight: bold;
          background-color: #ffd2d2;
          border-radius: 0.25rem;
          padding: 0 0.25rem;
        }
      }
    }

    .triangle-indicator {
      @include pres.width-height(1.5rem, 8rem);
      margin: 0.5rem;
      border-top: 4rem solid transparent;
      border-left: 1.5rem solid #f0f0f0;
      border-bottom: 4rem solid transparent;
      position: relative;
    }

    .triangle-indicator-sm {
      @include pres.width-height(0.5rem, 1rem);
      margin: 0 0.5rem;
      border-top: 0.5rem solid transparent;
      border-left: 0.5rem solid #e0e0e0;
      border-bottom: 0.5rem solid transparent;
    }

    .triangle-indicator-sm,
    .weight-calc {
      display: none;
    }
  }

  .show-weights {
    .triangle-indicator-sm,
    .weight-calc {
      display: inline-block !important;
    }

    .triangle-indicator {
      @include pres.width-height(1rem, 8rem);
      border-top: 4rem solid transparent;
      border-left: 1rem solid #efefef;
      border-bottom: 4rem solid transparent;
    }
  }

  p.info {
    color: vars.$pale-text-color;
    margin: 1rem 0 0 0;
    text-align: center;

    .sm-view {
      display: none;
    }
  }

  .ctrls {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid vars.$border-soft-color;

    .ctrl-wrapper {
      text-align: center;
      vertical-align: middle;
      margin-bottom: 0.5rem;

      input[type='checkbox'] {
        margin-right: 0.5rem;
        cursor: pointer;
        vertical-align: middle;
      }

      label {
        color: vars.$pale-text-color;
        font-size: vars.$font-size-xxs;
        cursor: pointer;
        vertical-align: middle;
        margin: 0;
        user-select: none;
      }
    }
  }

  .confirm-btn-wrapper {
    @extend %flex-row-jc;
  }

  #resubmit-btn {
    margin: 0.5rem;
  }
}

.results-container {
  @extend %flex-row-jc;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;

  .primary-section,
  .secondary-section {
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .panel-sm {
      flex-grow: 0;
      width: 25rem;
    }
  }

  .secondary-section {
    margin-bottom: 4rem;
  }

  .reflection-container {
    margin-left: 1.5em;
    margin-right: 1.5em;
  }

  .resources-card {
    p {
      margin: 1.5rem 0;
      color: vars.$pale-text-color;
      text-align: center;
    }

    .resources-container {
      text-align: center;
      padding-left: 2rem;

      a {
        @extend %flex-row-ac;
        margin-bottom: 1.5rem;

        i {
          font-size: vars.$font-size-sm;
          color: vars.$pale-text-color;
          background-color: #f6f6f6;
          border-radius: 0.5rem;
          margin-right: 1rem;
          padding: 0.25rem;
        }

        color: vars.$navbar-color;
        text-decoration: none;
        font-size: vars.$font-size-xs;
        font-weight: bold;

        &:hover {
          color: darken(vars.$navbar-color, 15%) !important;
        }

        &:visited {
          color: vars.$pale-text-color;
        }
      }
    }
  }

  .peer-comments-card {
    position: relative;

    .filter-tab {
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }

    .comments-wrapper {
      blockquote.peer-comment {
        padding: 0.5rem 1rem 1rem 1rem;
        background-color: #f6f6f6;

        &::after {
          border-top-color: #f6f6f6;
        }

        .comment-label {
          margin: 0.5rem 0 0 0.5rem;
        }
      }
    }

    a#see-dialogue-btn {
      @include pres.inset(auto, auto, 1rem, 6.5rem);
      position: absolute;
      width: 12rem;
      z-index: 200;
    }
  }

  .eval-avg-scores-card {
    align-items: center;

    .overall-grade-wrapper {
      margin-bottom: 1rem;

      h3 {
        text-align: center;
      }
    }
  }
}

.rubric-scores-card {
  flex-grow: 0;

  .scores-wrapper {
    .score {
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      font-size: vars.$font-size-sm;

      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:last-child {
        margin: 0;
      }

      .name-label {
        word-break: break-word;
      }

      .score-bar {
        height: 3rem;
        position: relative;

        &:hover {
          .bar-fill,
          .major-indicator,
          .minor-indicator {
            opacity: 0.5;
            transition: 0.1s;
          }
        }

        .bar-fill {
          transition: 1s;
        }

        .indicator-container {
          @extend %absolute-and-fill-space;

          .major-indicator,
          .minor-indicator {
            @extend %flex-row-jc-ac;
            border-radius: 50%;
            transition: transform 1s;
            user-select: none;
          }

          .major-indicator {
            @include pres.width-height(3rem);

            i {
              font-size: vars.$font-size-md;
            }
          }

          .minor-indicator {
            @include pres.width-height(2rem);
            margin: 0.5rem;

            i {
              font-size: vars.$font-size-sm;
            }
          }

          .class-above {
            background-color: #d9d9d9;

            i {
              color: #a760de;
            }
          }
        }
      }

      .top {
        path.bar-fill {
          stroke: #58cf7c;
        }

        .major-indicator,
        .minor-indicator {
          background-color: #58cf7c;

          i {
            color: #3b4f9f;
          }
        }
      }

      .mid {
        path.bar-fill {
          stroke: #e9e335;
        }

        .major-indicator,
        .minor-indicator {
          background-color: #e9e335;

          i {
            color: #906f1d;
          }
        }
      }

      .bot {
        path.bar-fill {
          stroke: #eb7373;
        }

        .major-indicator,
        .minor-indicator {
          background-color: #eb7373;

          i {
            color: #822557;
          }
        }
      }

      .score-details-wrapper {
        @extend %flex-row;
        justify-content: space-between;
        align-items: baseline;

        .score-label {
          font-weight: bold;
          span.user-score {
            font-size: vars.$font-size-md;
          }
        }

        .class-comparator {
          @extend %flex-row;
          align-items: flex-end;
          font-size: vars.$font-size-xs;
        }

        .expand-btn {
          @extend %default-btn;
          width: 5rem;
          font-size: vars.$font-size-xs;
          color: #589fcf;
          text-transform: uppercase;

          i {
            font-size: vars.$font-size-xs;
            color: vars.$secondary-color-2;
          }
        }
      }

      .score-dropdown {
        .average-data {
          @extend %flex-row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 1rem 0;
          font-size: vars.$font-size-xs;

          span {
            @extend %flex-row;

            i {
              margin-right: 0.25rem;
            }

            b {
              margin-left: 0.25rem;
            }
          }
        }

        .rating-levels-list {
          @extend %default-list;
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 4rem;

          .rating-level-wrapper {
            @extend %flex-row-ac;
            padding: 0.5rem 1rem;
            border-radius: 10rem;

            &:nth-child(odd) {
              background-color: white;
            }

            &:nth-child(even) {
              background-color: #f9f9f9;
            }

            .score-icon {
              @extend %flex-row-jc-ac;
              @include pres.width-height(2rem);
              flex: 1 0 auto;
              margin-right: 1rem;
              border: 2px solid vars.$primary-color-2;
              border-radius: 50%;
              color: vars.$primary-color-2;
            }

            .description-label {
              flex: 0 1 auto;
              font-size: vars.$font-size-xs;
            }
          }

          .highlighted {
            background-color: vars.$primary-color-2 !important;
            color: white;

            .score-icon {
              border-color: white !important;
              color: white !important;
            }
          }
        }
      }
    }

    .top .score-label,
    .above {
      color: #30a653;
    }

    .mid .score-label {
      color: #b98e27;
    }

    .bot .score-label,
    .below {
      color: #ea6c6c;
    }

    .equal {
      color: #9ba2a8;
    }
  }
}

.teacher-results-container {
  @extend %flex-col;
  align-content: stretch;
  flex-grow: 1;

  nav.mininav {
    top: 2rem;
  }

  .card-menu-wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  #highlights-card {
    flex-direction: column;
    align-self: center;
    padding: 2rem;

    .interactions-wrapper {
      @extend %flex-row-jc-ac;
      margin: 1rem 0;

      p {
        font-size: vars.$font-size-sm;
        margin-right: 1rem;
      }
    }
  }

  #breakdown-graph-card {
    max-width: 54rem;
    height: 32rem;
    align-self: stretch;

    .graph-wrapper {
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    .tooltip {
      margin: 0.5rem;

      p {
        font-size: vars.$font-size-xs;
        margin: 0;
        line-height: calc(#{vars.$font-size-xs} + 0.5rem);
      }
    }
  }

  .unavailable-card {
    display: inline-block;
    margin: 1rem;
  }

  #overview-tab {
    @extend %flex-col-ac;

    #actions-card {
      .alert-bar {
        margin-bottom: 0.5rem;
      }

      p.visibility-status {
        text-align: center;
        margin-bottom: 0.5rem;
        b {
          margin-right: 1rem;
        }
      }

      i {
        font-size: 22px;
        margin-right: 0.5rem;
      }

      button,
      a {
        margin-bottom: 0.25rem;
      }
    }
  }
}

#advanced-visibility-settings-menu {
  .visibility-setting {
    @extend %flex-row-ac;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    label {
      margin: 0 1rem 0 0;
    }
  }
}

.results-container,
.teacher-results-container {
  min-width: 0;
  color: vars.$navbar-color;

  .panel-sm {
    margin: 1rem;
    padding: 1rem 1.5rem;
    flex-direction: column;
  }

  .empty {
    height: auto !important;

    &::after {
      box-shadow: none !important;
    }
  }

  .results-card-long,
  .results-card-wide {
    @include pres.width-height(25rem, 42rem);
    position: relative;
    padding-bottom: 2em;

    &::after {
      @include pres.inset(0, 0, 1.9em, 0);
      content: '';
      position: absolute;
      box-shadow: inset 0 -8rem 4rem -4rem white;
      pointer-events: none;
    }

    .scores-wrapper,
    .comments-wrapper {
      overflow: scroll;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .score:last-child,
      blockquote.peer-comment:last-child {
        margin-bottom: 4rem !important;
      }
    }
  }

  .results-card-wide {
    @include pres.width-height(40rem, 34rem);

    .scores-wrapper {
      @extend %flex-row-jc-ac;
      flex-wrap: wrap;
      justify-content: flex-start;

      .score {
        // flex-basis: 50%;
        width: 18rem;
        background-color: white;

        &:nth-child(odd) {
          padding-right: 2rem;
        }

        &:nth-child(even) {
          padding-left: 2rem;
        }
      }
    }
  }

  section {
    @extend %flex-row-jc-ac;

    #individual-assignment-grades-card {
      flex-grow: 0 !important;
    }
  }
}

.back-to-top {
  padding-top: 2rem;
  padding-bottom: 3rem;
  text-align: center;
  cursor: pointer;
  flex-basis: 100%;

  p {
    text-transform: uppercase;
    color: #2d2d2d;
    font-weight: bold;
    user-select: none;
  }
}

.feedback-result {
  @extend %flex-col-ac;
  padding: 1rem;
  max-width: 480px;

  blockquote {
    max-width: 100%;
    animation: none;
    margin-left: 0;
    overflow-wrap: break-word;
  }

  .review-comment {
    background-color: #e3e3e3;
    padding-bottom: 2rem;
    position: relative;

    .comment-prompt {
      font-style: italic;
      margin: -0.5rem 0 0.5rem -0.5rem;
    }

    &::after {
      border-bottom-color: #e3e3e3;
    }
  }

  .feedback-wrapper {
    @include pres.panel-padding-radius(1rem, 1rem);
    max-width: 320px;
    margin-top: -2rem;
    z-index: 56;

    .feedback-rating {
      width: 112px;
      background-color: #f8f8f8;
      text-align: center;
      border-radius: 1rem;
      margin: 0 auto;

      i {
        vertical-align: middle;
        font-size: vars.$font-size-xs;
      }
    }

    .feedback-comment {
      background-color: transparent;
      padding: 0 0 0 1.5rem;
      margin: 0.5rem 0 0 0;

      &::before {
        content: 'person_outline';
        font-family: 'Material Icons';
        position: absolute;
        left: -0.5rem;
        color: vars.$pale-text-color;
        font-size: vars.$font-size-sm;
        line-height: 1.25rem;
        color: #9ba7af;
      }

      &::after {
        content: none;
      }
    }
  }
}

.review-results {
  @extend %flex-col-ac;
  padding: 1rem;
  max-width: 1280px;
  margin-bottom: 8rem;

  h2,
  p {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  p {
    line-height: 2rem;
  }

  fieldset.submission-choice {
    @extend %flex-row-jc;
    flex-wrap: wrap;
    padding: 0.5rem;

    legend {
      font-weight: bold;
      text-decoration: underline;
      text-align: center;
    }

    .rad-radio-btn {
      margin: 0.125rem;
    }

    label {
      background-color: white;
      border-radius: 1rem;
      font-weight: bold !important;
    }
  }

  .accuracy-card {
    @include pres.panel-padding-radius(1rem 2rem, 2rem);

    .filter-tab,
    .filter-tab legend {
      font-size: vars.$font-size-xxs !important;
    }

    p.definition {
      max-width: 500px;
      margin: 0;
    }

    p.caption {
      color: vars.$pale-text-color;
      margin: 1rem 0 0 0;
      text-align: center;
    }

    .accuracy-display {
      @extend %flex-row-jc-ac;
      flex-wrap: wrap;

      .target-graph {
        margin: 1rem 0;
      }

      table {
        margin-left: 2rem;
        flex-grow: 1;

        tr {
          max-width: 100%;
        }

        th.name,
        td.name {
          text-align: left;
          max-width: 300px;
          overflow-wrap: break-word;
          width: 70%;
        }

        th.score,
        td.score {
          text-align: center;
          font-weight: bold;
          width: 30%;
        }

        thead,
        tbody,
        tr {
          display: block;
        }

        thead {
          border-bottom: 1px solid vars.$border-soft-color;
        }

        th,
        td {
          display: inline-block;
          pointer-events: none;
        }

        tbody {
          max-height: 16rem;
          overflow-y: auto;

          tr {
            background-color: white;

            &:hover {
              background-color: #f5f5f5;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .helpfulness-card {
    @extend %flex-col-ac;
    @include pres.panel-padding-radius(1rem, 2rem);

    .star-rating {
      position: relative;
      margin-bottom: 0.5rem;

      .score {
        position: absolute;
        line-height: 2rem;
        vertical-align: middle;
        right: calc(100% + 0.5rem);
        font-weight: bold;
      }

      .stars {
        display: inline-flex !important;
        padding: 0.25rem 1rem;
        border-radius: 10rem;
        background-color: #f8f8f8;
      }
    }
  }

  section {
    h3 {
      text-align: center;
    }

    a.resource-link {
      display: block;
      text-align: center;
      text-decoration: underline;
      font-size: vars.$font-size-xxs;
      margin-bottom: 1rem;
    }

    margin-bottom: 2rem;
  }

  .no-results {
    flex-direction: column;
  }
}

#results-full-dialogue {
  &.submission-collapsed {
    flex-direction: column;
  }

  .dialogue-col {
    @extend %flex-col;
    padding: 1rem;
    flex: 0 1 50%;
  }

  .review-submission-window {
    flex-grow: 1;
  }

  #quote-col {
    .title-bar {
      @extend %flex-row;
      @include pres.panel-padding-radius(0.5rem 1rem, 0 0 0.5rem 0.5rem);
      justify-content: space-between;
      margin-top: -1rem;

      .item {
        display: inline-flex;
        align-items: center;

        &:first-child {
          flex-wrap: wrap;
        }
      }

      h1 {
        font-weight: normal;
        font-style: italic;
        font-size: vars.$font-size-xs !important;
        margin: 0 1rem 0 0;
        display: inline;
      }

      .prompt-btn {
        @extend %default-btn;
        text-transform: uppercase;
        letter-spacing: 1px;
        word-spacing: 1px;
        font-size: 12px;
        background-color: #e5e5e5;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &:hover {
          background-color: #d7d7d7;
        }
      }

      button#back-btn {
        margin-right: 0.25rem;
      }
    }
  }
}

@include pres.bp-laptop-sm {
  .results-row,
  .teacher-results-row {
    flex-direction: column;
    align-items: stretch !important;
  }
}

@include pres.bp-tablet-lg {
  .teacher-results-container {
    .results-card-wide {
      width: 100%;

      .scores-wrapper {
        justify-content: center !important;

        .score {
          padding: 1rem !important;
        }
      }
    }
  }

  .results-container {
    .panel-sm {
      margin: 0.5rem !important;
    }

    .grades-card p.info {
      .lg-view {
        display: none !important;
      }
      .sm-view {
        display: inline !important;
      }
    }
  }
}

@include pres.bp-tablet-sm {
  .teacher-results-container {
    .panel-sm {
      margin: 1rem 0 !important;
    }
  }

  #results-full-dialogue {
    flex-direction: column;
  }
}

@include pres.bp-mobile-lg {
  .results-container {
    .primary-section,
    .secondary-section {
      .panel-sm {
        width: 19rem;
      }
    }
  }

  .results-row {
    .grades-container {
      flex-direction: column-reverse !important;

      .sub-grade-wrapper {
        flex-direction: column !important;
      }

      .triangle-indicator {
        margin: -4rem 0 0.5rem 0 !important;
        height: 1rem !important;
        width: 8rem !important;
        border-right: 4rem solid transparent !important;
        border-bottom: 1rem solid #f0f0f0 !important;
        border-left: 4rem solid transparent !important;
      }
    }

    .score-details-wrapper {
      justify-content: flex-start !important;
      flex-wrap: wrap;

      .class-comparator {
        margin-left: 1rem;
      }

      .expand-btn {
        flex-basis: 100%;
      }
    }

    a#see-dialogue-btn {
      left: 3.5rem !important;
    }

    .average-data {
      justify-content: center !important;
    }
  }

  .review-results .accuracy-card {
    padding: 1rem;

    table {
      margin: 0 !important;

      td.name {
        max-width: 250px !important;
      }
    }
  }
}

.external-reviewer{
  padding:5rem
}
