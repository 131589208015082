@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

#home-banner,
#user-banner {
  @extend %flex-row-jc;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    @extend %absolute-and-fill-space;
    content: '';
    background-color: #528bb5;
    background-image: linear-gradient(rgba(255, 255, 0, 0) 30%, #528bb5 99%),
      url('/assets/patterns/brand-pattern-home-1.svg'), url('/assets/patterns/brand-pattern-home-2.svg'),
      url('/assets/patterns/brand-pattern-home-3.svg'), url('/assets/patterns/brand-pattern-home-4.svg');
    background-size: 336px;
    background-repeat: repeat-x;
    background-position: 0, 0 -5px, 20px 30px, 0 65px, 20px 100px;
    filter: brightness(0.5);
    z-index: 250;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: calc(50vw - 184px);
    bottom: 0;
    left: calc(50vw - 184px);
    background-color: #29455a;
    background-image: url('/assets/white_text_logo.svg');
    background-repeat: no-repeat;
    background-position: center 0.5rem;
    background-size: 240px;
    pointer-events: none;
    z-index: 500;
    box-shadow: 0 0 2rem 1rem #29455a;
  }

  .banner-content {
    @extend %flex-row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    position: relative;
    z-index: 1000;
    max-width: 1200px;
    margin-top: 55px;

    #profile-preview,
    .home-links {
      margin-top: 0.5rem;
    }

    #profile-preview {
      @extend %flex-row-ac;
      position: relative;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      color: white;
      text-decoration: none !important;
      border-radius: 10rem;

      &:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }

      .avatar {
        margin: 0 10px;
        position: absolute;
        right: 100%;
        left: 0;
      }

      .details {
        display: inline-block;
        margin: 0 1rem 0 0;
        padding: 0.25rem 0 0.25rem 5rem;

        .name {
          font-size: vars.$font-size-sm;
          line-height: vars.$font-size-sm;
          font-weight: bold;
        }

        .role {
          text-transform: capitalize;
        }
      }
    }

    .home-links {
      @extend %flex-row;
      padding-bottom: 0.5rem;

      a {
        @extend %text-all-caps;
        color: white;
        font-size: vars.$font-size-xxs;
        vertical-align: middle;
        line-height: 20px;
        padding: 0.75rem;
        border-radius: 10rem;

        i {
          font-size: 20px;
          vertical-align: middle;
          margin-right: 0.25rem;
        }

        &.selected {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

#user-banner {
  &::after {
    background-image: none !important;
  }

  .banner-content {
    flex-direction: column;
    margin: 0;
    padding: 1rem 0;
    align-items: center;

    .ctrls {
      @extend %flex-row;
      vertical-align: middle;

      button,
      a {
        @extend %default-btn;
        border-radius: 10rem;
        border: 1px solid white;
        color: white;
        font-size: vars.$font-size-xxs;
        letter-spacing: 1px;
        padding: 0 0.75rem;
        margin: 0 0.125rem;
        text-decoration: none !important;

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }

        i {
          font-size: vars.$font-size-xs;
          line-height: vars.$font-size-xs;
          vertical-align: middle;
        }
      }
    }
  }

  #profile-preview {
    background-color: transparent !important;
  }
}

@include pres.bp-tablet-lg {
  #home-banner .banner-content {
    flex-direction: column;
    align-items: center;
  }
}

@include pres.bp-tablet-sm {
  #home-banner,
  #user-banner {
    &::after {
      right: calc(50vw - 80px);
      left: calc(50vw - 80px);
      background-size: 160px;
    }

    #profile-preview {
      margin: 0.25rem !important;

      .avatar {
        width: 32px !important;
        height: 32px !important;
        font-size: vars.$font-size-xs !important;
      }

      .details {
        padding-left: 3rem !important;

        .name {
          font-size: vars.$font-size-xs !important;
          line-height: vars.$font-size-xs !important;
        }

        .role {
          font-size: vars.$font-size-xxs !important;
        }
      }
    }
  }

  #home-banner .banner-content {
    margin-top: 3rem;

    .home-links {
      margin-top: 0 !important;

      a {
        font-size: 12px !important;
        text-transform: none !important;
        padding: 0.5rem;
        letter-spacing: 1px !important;
        text-align: center;

        i {
          font-size: 16px;
        }
      }
    }
  }

  #user-banner .banner-content {
    padding: 0.5rem;
  }
}
