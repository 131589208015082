@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.peer-sidebar,
.peer-sidebar-skeleton {
  @extend %flex-col;
  @include pres.panel-padding-radius(1rem, 0 2rem 2rem 0);
  justify-content: space-between;
  position: relative;
  background-color: white;
  min-height: calc(100vh - #{vars.$navbar-height} - 1rem);
  max-height: calc(100vh - #{vars.$navbar-height} - 1rem);
  position: sticky;
  top: 0.5rem;
  margin: 0.5rem 0 0.5rem 0;
  border-left: 1px solid #dcdcdc;

  i {
    color: vars.$icon-color;
  }

  // TODO: Remove and replace with better vertical responsiveness
  // This is a temporary vertical responsiveness fix
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .sidebar-menu-label {
    @extend %text-all-caps;
    font-size: 10px;
    color: vars.$pale-text-color;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0.2rem;
    left: 0;
    user-select: none;
  }
}

.sidebar-maximized,
.peer-sidebar-skeleton {
  width: 16rem;
  min-width: 16rem;
  max-width: 16rem;
}

.sidebar-heading,
.mobile-menu-top {
  @extend %flex-row;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.5rem;

  i {
    display: none; // Temporary fix, TODO: Have a better location or use for these buttons
    color: vars.$icon-color;
    font-size: vars.$icon-size;
  }

  .sidebar-heading-title {
    padding: 0.25rem;
    color: vars.$pale-text-color;
    border-radius: 0.5rem !important;
    cursor: pointer;
    text-decoration: none;
    flex-grow: 1;

    .title,
    .subtitle {
      line-height: 1.5rem;
      font-weight: 400;
    }

    .title {
      font-weight: bold;
      font-size: vars.$font-size-xs;
    }

    .subtitle {
      font-size: 12px !important;
      margin: 0;
    }

    &:hover {
      background-color: rgb(235, 235, 235);
      color: black;
    }
  }

  .sidebar-heading-icons {
    display: none; // Temporary fix, TODO: Have a better location or use for these buttons
    @extend %flex-col;
    justify-content: space-between;
    padding-bottom: 0.5rem;

    i {
      font-size: vars.$font-size-sm;
      margin: 0.5rem;
    }
  }
}

.links-container {
  @extend %flex-col;
  margin: 1rem 0;

  a.sidebar-link {
    @extend %flex-row-ac;
    padding: 1rem;
    margin-bottom: 0.25rem;
    font-size: vars.$font-size-xs;
    text-decoration: none;
    color: vars.$icon-color;
    border-radius: 100px;

    i {
      font-size: vars.$font-size-sm;
      margin-right: 1rem;
    }

    &:hover {
      background-color: rgb(235, 235, 235);
      color: black;
    }
  }

  a.sidebar-link-selected {
    background-color: vars.$primary-color-2 !important;
    color: white !important;

    i {
      color: white;
    }
  }
}

.sidebar-minimize-btn {
  margin-top: 1rem;
  margin-left: calc(100% - #{vars.$font-size-sm} - 1rem);
  cursor: pointer;

  i {
    user-select: none;
    font-size: vars.$font-size-sm;
  }
}

.sidebar-minimized {
  padding: 0.5rem;

  .sidebar-menu-label {
    display: none;
  }

  .sidebar-heading {
    display: none;
  }

  .links-container {
    a.sidebar-link {
      padding: 1rem;
      justify-content: center;

      i {
        margin: 0;
      }

      .sidebar-link-name {
        display: none;
      }
    }
  }

  .sidebar-minimize-btn {
    margin-left: calc(50% - #{vars.$font-size-md}/ 2);
  }
}

.mobile-menu-top {
  background-color: vars.$navbar-color;
  align-items: center;
  flex-grow: 0;
  z-index: 1000;
  border: none !important;

  .sidebar-heading-title {
    color: white !important;
    margin: 0;
    flex-grow: 1;

    .title {
      font-weight: normal !important;
      line-height: 1.25rem !important;

      span {
        font-weight: inherit !important;
      }
    }

    br {
      display: none;
    }

    &:hover {
      background-color: lighten(vars.$navbar-color, 10%);
      color: black;
    }
  }
}

.mobile-menu-bottom {
  @extend %flex-row-jc-ac;
  @include pres.panel-padding-radius(0.5rem, 0);
  @include pres.inset(auto, 0, 0, 0);
  height: vars.$tab-bar-height;
  position: fixed;
  z-index: 1000;

  .links-container {
    @extend %flex-row;
    justify-content: space-evenly;
    flex: 4 1 auto;
    margin: 0;

    a.sidebar-link {
      @include pres.width-height(3rem);
      padding: 0.5rem;
      margin: 0;
      justify-content: center;
      align-items: center;

      i {
        margin: 0;
      }

      span {
        display: none;
      }
    }
  }

  .profile-wrapper {
    @extend %flex-row-jc;
    flex: 1 2 auto;
  }
}

@include pres.bp-laptop-md {
  .peer-sidebar {
    padding: 1em 0.5em;
  }

  .sidebar-maximized,
  .peer-sidebar-skeleton {
    width: 14rem;
    min-width: 14rem;
    max-width: 14rem;

    .sidebar-heading {
      flex-direction: column-reverse;

      .sidebar-heading-title {
        margin: 0 !important;
      }

      .sidebar-heading-icons {
        flex-direction: row !important;
        justify-content: space-evenly !important;

        i {
          font-size: vars.$icon-size !important;
          margin: 0 !important;
        }
      }
    }

    .sidebar-link {
      padding: 1rem !important;
    }
  }
}

@include pres.bp-laptop-sm {
  .peer-sidebar-skeleton {
    display: none !important;
  }

  .mobile-menu-top {
    .sidebar-heading-title {
      .title {
        font-size: vars.$font-size-xxs !important;
        line-height: vars.$font-size-xxs !important;
        margin: 0;

        span {
          font-size: inherit;
        }
      }
    }
  }
}

@include pres.bp-tablet-sm {
  .mobile-menu-top {
    padding: 0.5em !important;
  }
}

@include pres.bp-mobile-lg {
  .sidebar-heading-title {
    .title,
    .subtitle {
      line-height: 1.5rem !important;
    }

    .title {
      font-size: vars.$font-size-xs !important;
      font-weight: 600 !important;
    }
  }

  .profile-wrapper {
    display: none !important;
  }
}
