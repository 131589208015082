@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

$toolbar-height: 3rem;

.pdf-canvas-viewer {
  @include pres.width-height(100%);
  position: relative;
  min-height: 75vh;

  .toolbar {
    @extend %flex-row-jc-ac;
    height: $toolbar-height;
    background-color: rgb(80, 80, 80);
    font-size: vars.$font-size-xxs;
    color: white;

    input[type='text'] {
      border-radius: 2px;
      padding: 0.25rem;
      font-size: vars.$font-size-xxs;
      color: white;
      text-align: center;
      background-color: rgb(43, 43, 43);
      border: none;
      box-shadow: none;
    }

    button {
      margin: 0 0.25rem;
      padding: 0.25rem 0.5rem;

      i {
        color: white !important;
        font-size: vars.$font-size-xs;
      }

      &:hover {
        background-color: rgb(97, 97, 97);
      }
    }

    input,
    button {
      &:disabled {
        cursor: wait;
      }
    }

    .nav-ctrls {
      padding: 0 1rem !important;

      input[type='text'] {
        width: 2rem;
        margin-right: 0.125rem;
      }
    }

    .zoom-ctrls {
      input[type='text'] {
        width: 3.5rem;
      }
    }

    .ctrl-group:not(:last-child) {
      padding-right: 0.5rem;
      border-right: 1px solid gray;
      margin-right: 0.5rem;
    }
  }

  .pdf-scroll-wrapper {
    background-color: gray;
  }

  .pdf-scroll-wrapper {
    @extend %absolute-and-fill-space;
    top: $toolbar-height;
    overflow: auto;
  }

  .pin-ready-overlay,
  .error-overlay {
    @extend %flex-row-ac;
    @extend %absolute-and-fill-space;
    top: $toolbar-height;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: vars.$font-size-lg;
    text-align: center;
  }

  .error-overlay {
    padding: 0 2rem;
    font-size: vars.$font-size-md !important;

    i {
      font-size: vars.$font-size-md !important;
    }
  }

  .pdf-container {
    .pdf-page {
      position: relative;
      margin: 0.5rem auto;

      canvas.pdf-canvas {
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.4);
      }

      canvas.draw-canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        transition: opacity linear 0.1s;
      }

      .text-layer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        opacity: 0.2;
        line-height: 1;
        color: transparent !important;

        * {
          position: absolute;
          transform-origin: left top;
        }
      }
    }
  }

  .draw-enabled {
    canvas.draw-canvas {
      pointer-events: auto !important;
      cursor: pointer;
      opacity: 1 !important;
    }
  }

  .hide-annotations {
    canvas.draw-canvas {
      opacity: 0;
    }
  }
}
