@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

.expanded-student-row {
  @extend %flex-col;
  flex-wrap: wrap;

  .action-section {
    @extend %flex-col-jc;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid #e2e2e2;
    margin: 0 0 1rem 0;

    &:last-child {
      border: none;
      margin: 0 !important;
    }

    button,
    a {
      margin-bottom: 1rem;
    }

    *:last-child {
      margin-bottom: 0;
    }

    .user-details {
      text-align: left;

      p,
      a {
        margin: 0 0 0.5rem 0;
      }
    }

    a#details-link {
      margin-top: 1rem;
    }

    .grade-display {
      @extend %flex-col-jc-ac;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p#override-text {
      text-align: center;
      margin-bottom: 0.5rem;
      color: vars.$pale-text-color;
      vertical-align: middle;

      i {
        font-size: 20px;
        vertical-align: middle;
      }
    }

    .instructor-actions {
      @extend %flex-row;
      align-items: baseline;
      margin-bottom: 1rem;

      a,
      button,
      .confirm-btn-wrapper {
        flex: 1 0 auto;
        margin: 0 !important;
      }

      button.delete {
        margin-left: 0.5rem !important;
        flex-grow: 0;

        i {
          color: vars.$cancel-color;
        }
      }

      .confirm-btn-wrapper button {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

#override-input-wrapper {
  @extend %flex-row-jc-ac;
  flex-wrap: wrap;

  .override-section {
    @extend %flex-col-jc-ac;
    margin: 0 2rem 2rem 2rem;

    label,
    input {
      text-align: center;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .grade-conversion-display {
    @extend %flex-row-jc-ac;

    i {
      margin: 0 !important;
      font-size: 20px !important;
      color: vars.$pale-text-color !important;
    }

    .pending {
      font-size: vars.$font-size-xxs;
      margin-right: 0.5rem;
    }
  }

  .selection {
    display: inline-block;
  }

  .update-role-btn {
    padding-right: 0.5rem !important;
  }
}
