@use "../../../../styles/presets" as pres;
@use "../../../../styles/variables" as vars;

.query-table-ctrls,
.query-table-pagination-wrapper {
  @extend %flex-row-jc;
  padding: 1rem 0;
}

.query-table-ctrls {
  justify-content: space-between;

  .filter-btn {
    margin: 0 0.125rem 0.125rem 0.125rem !important;
  }

  & + div p.no-results {
    text-align: center;
    width: 100%;
    margin: 1rem 0;
  }
}

.query-table-wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
}

table.query-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;

  tr,
  th,
  td {
    border-left: none !important;
    border-right: none !important;
    padding: 0.5rem;
  }

  tr {
    position: relative;
  }

  thead {
    tr,
    th {
      border-top: none !important;
      border-bottom-color: #9f9f9f;
    }
  }

  tbody tr:hover {
    background-color: rgb(223, 223, 223);
    cursor: pointer;
  }

  .th-wrapper {
    @extend %flex-row-ac;
  }

  th {
    text-align: left;
    user-select: none;

    i.sort-icon,
    i.sort-icon-ghost {
      transform: rotate(90deg);
      font-size: 16px;
      vertical-align: middle;
      margin-left: 0.25rem;
    }

    i.sort-icon-ghost {
      opacity: 0;
    }
  }

  td.bold {
    font-weight: bold;
  }

  td.role {
    text-transform: capitalize;
  }

  td.select-row-btn {
    background-color: white !important;
  }

  th.center-align,
  td.center-align {
    text-align: center;

    .th-wrapper {
      justify-content: center;
    }
  }

  th:not(.not-sortable) {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.087);

      i.sort-icon-ghost {
        opacity: 0.25;
      }
    }
  }
}

@include pres.bp-laptop-sm {
  .query-table-ctrls,
  table.query-table,
  .query-table-pagination-wrapper {
    font-size: vars.$font-size-xxs !important;
  }
}
