@use '../../../styles/presets' as pres;
@use '../../../styles/variables' as vars;

#student-dashboard {
  %phase-icon {
    display: inline-block;
    background-color: black;
    color: white;
    border-radius: 50%;
    text-align: center;
    align-self: center;
    z-index: 1 !important;
    filter: drop-shadow(0px -12px 12px rgba(255, 255, 255, 0.575));
    user-select: none;

    i {
      font-size: 32px;
      vertical-align: -1.8rem;
    }
  }
  .dashboard {
    flex-grow: 1;

    .overview {
      @extend %flex-row-jc;
      padding: 2em;

      .card-wrapper {
        display: inline-flex;
        justify-content: center;
        gap: 2rem;

        #details-card {
          max-width: 768px;
          flex: 0 1 auto;
        }

        .now-phase {
          margin: 0;
          align-self: flex-start;
          flex: 0 0 auto;
        }
      }

      .button-row {
        @extend %flex-row;
        margin-bottom: 0.5rem;

        a,
        button {
          margin-right: 0.5rem;
          margin-bottom: 0;
          border-radius: 0.5rem !important;
          text-transform: uppercase !important;
          letter-spacing: 3px !important;
        }

        .peer-dropdown {
          margin-right: 0.5rem;
        }
      }

      .curr-task-wrapper {
        margin-bottom: 1rem;
      }

      .to-do-wrapper {
        @extend %flex-row;
        align-items: baseline;

        button#next-task-btn,
        a#next-task-btn {
          border-radius: 0.5rem !important;
          animation: pulse 2s infinite;
          margin: 0;
        }

        .next-task-label {
          margin-right: 1rem;
          font-weight: bold;
        }

        p {
          display: inline-block;
          margin: 0;
        }

        i {
          color: vars.$error-color;
          margin-right: 0.5rem;
          vertical-align: bottom;
        }
      }
    }

    .dashboard-cards-container {
      @extend %flex-row-jc-ac;
      align-items: stretch;
      align-self: flex-start;
      flex-wrap: wrap;
      color: vars.$navbar-color;
      margin-bottom: 6rem;

      .phase-card {
        display: inline-flex !important;
        position: relative;
        margin: 5rem 1rem 1rem 1rem;
        padding: 2rem;
        width: 20rem !important;
        max-width: 20rem !important;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        z-index: 10;

        .phase-icon {
          @extend %phase-icon;
          @include pres.width-height(8rem);
          position: absolute;
          bottom: calc(100% - 4rem);
          border: 2rem solid white;
        }

        .title-row {
          @extend %flex-row;
          justify-content: space-between;
          align-items: center;
          z-index: 10 !important;
          padding-bottom: 1rem;
          margin: 0.5rem 0 1rem 0;
          border-bottom: 1px solid vars.$border-soft-color;

          h2 {
            font-size: vars.$font-size-sm;

            &:only-child {
              flex: 1 0 auto;
            }
          }

          h2,
          p {
            margin: 0;
          }

          .deadline-wrapper {
            color: #666666;

            p {
              line-height: 16px;
            }

            span.time {
              font-size: 12px;
            }
          }
        }

        .unlock-info {
          margin: 0 -1rem 1rem -1rem;
          color: vars.$navbar-color;
          padding: 1rem;
          border-radius: 1rem;
          background-color: rgb(240, 240, 240);

          i {
            vertical-align: top;
            margin-left: -0.5rem;
            margin-right: 0.5rem;
          }
        }

        .review-icons-container,
        .feedback-icons-container,
        .reflection-icons-container {
          @extend %flex-row-jc-ac;
          overflow: hidden;

          i {
            user-select: none;
          }
        }

        .completed-text {
          position: relative;
          font-weight: bold;
        }

        .fail,
        .pass,
        .awaiting {
          &::after {
            font-family: 'Material Icons';
            font-size: vars.$font-size-sm;
            position: absolute;
            transform: translateX(0.5rem);
            top: -0.5rem;
          }
        }

        .pass::after {
          content: 'check';
          color: #38b934;
        }

        .fail::after {
          content: 'close';
          color: vars.$cancel-color;
        }

        .awaiting::after {
          content: 'schedule';
          color: vars.$navbar-color;
          font-size: 20px;
          top: -0.3rem;
        }
      }

      .locked {
        background-color: #e4e4e4 !important;
        color: vars.$pale-text-color !important;
        box-shadow: none !important;

        p {
          color: #636363 !important;
        }

        .phase-icon {
          border-color: #e4e4e4 !important;
          background-color: #a0a0a0 !important;
          filter: none !important;
        }

        .title-row {
          border-bottom-color: rgb(175, 175, 175);
        }
      }

      #submit-card {
        .phase-icon {
          background-color: #7878f1;
        }
      }

      #review-card {
        .phase-icon {
          background-color: #e676e3;
        }

        .bonus-wrapper {
          margin-top: 0.5rem;

          button {
            width: 100%;
            margin: 0;
          }
        }
      }

      #feedback-card {
        .phase-icon {
          background-color: #e4c445;
        }
      }

      #evaluate-card {
        .phase-icon {
          background-color: #e4c445;
        }
      }

      #reflect-card {
        .phase-icon {
          background-color: #0096ff;
        }
      }
    }

    button,
    a {
      margin-bottom: 0.5rem;
      text-transform: none !important;
      border-radius: 10rem !important;
      letter-spacing: 1px !important;
    }
  }

  .review-icon,
  .feedback-icon,
  .reflection-icon {
    @include pres.width-height(2.5rem);
    position: relative;
    display: inline-block;
    margin: 0 0.25rem !important;
    padding: 0 !important;

    i {
      top: -9px;
      position: relative;
      font-family: 'Material Icons';
      font-style: normal;
      font-size: 36px;
      z-index: 10;
    }

    &::before {
      @extend %absolute-and-fill-space;
      font-family: 'Material Icons';
      color: vars.$navbar-color;
      font-size: 40px;
      z-index: 1;
      top: -0.75rem;
      pointer-events: none;
    }
  }

  .review-icon {
    i.complete {
      color: #f39cf0;
    }

    &::before {
      content: 'chat_bubble';
      filter: drop-shadow(0 2px 0 vars.$navbar-color);
    }
  }

  .feedback-icon {
    i.complete {
      color: #f1da7d;
    }

    &::before {
      content: 'question_answer';
      filter: drop-shadow(0 2px 0 vars.$navbar-color);
    }
  }

  .reflection-icon {
    i.complete {
      color: #0096ff;
    }
    &::before {
      content: 'chat_bubble';
      filter: drop-shadow(0 2px 0 vars.$navbar-color);
    }
  }
}

@include pres.bp-laptop-sm {
  #student-dashboard {
    flex-direction: column;
    align-items: stretch !important;
  }
}

@include pres.bp-tablet-lg {
  #student-dashboard {
    .overview {
      padding: 1rem !important;

      .card-wrapper {
        flex-wrap: wrap;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  #student-dashboard {
    .overview {
      padding: 0.5rem !important;
    }

    .now-phase {
      margin-top: 1rem;
    }
  }
}
