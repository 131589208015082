@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

img {
  &.focused {
    box-shadow: 0 0 0 4px vars.$border-soft-color;
    transition: box-shadow 0.1s;
  }

  &.unfocused {
    box-shadow: none;
    transition: box-shadow 0.1s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.rich-editor,
.DraftEditor-root,
.DraftEditor-editorContainer {
  @extend %flex-col;
  flex-grow: 1;
}

.rich-editor {
  .public-DraftEditor-content {
    @include pres.panel-padding-radius(1rem, 0 0 1rem 1rem);
    min-height: 8rem;
    background-color: #eeeeee;
    overflow-y: scroll;
    border: 1px solid vars.$border-soft-color;
    overflow: auto;
    transition: box-shadow 0.2s, border-color 0.2s;
    flex-grow: 1;

    &:focus {
      border-color: #afafaf;
      box-shadow: inset 0 0 1rem 2px #bebebe34;
    }
  }

  .rich-toolbar {
    @extend %flex-row;
    @include pres.panel-padding-radius(0.25rem 0.5rem, 1rem 1rem 0 0);
    flex-grow: 0;
    border: 1px solid #dcdcdc;
    z-index: 2;

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #333;
      border-width: 4px;
      margin-left: -4px;
    }

    &:before {
      border-color: rgba(221, 221, 221, 0);
      border-top-color: #111;
      border-width: 6px;
      margin-left: -6px;
    }

    .controls-separator {
      padding-right: 0.5rem;
      margin-right: 0.5rem;
      height: 2rem;
      border-right: 1px solid vars.$border-soft-color;
    }

    .rich-ctrl-btn-wrapper {
      position: relative;
      display: inline-block;

      button.rich-ctrl-btn {
        @extend %default-btn;
        height: 2rem;
        border-radius: 4px;

        i,
        svg {
          fill: vars.$icon-color;
          color: vars.$icon-color;
          vertical-align: middle;
        }

        &:hover,
        &:focus {
          background: rgba(0, 0, 0, 0.048);
        }
      }

      button.active {
        color: #6a9cc9;

        i,
        svg {
          fill: #6a9cc9;
          color: #6a9cc9;
        }
      }

      .editor-menu-modal {
        @extend %flex-col;
        max-width: 320px;
        position: absolute;
        border: 1px solid vars.$border-soft-color;
        border-top-left-radius: 4px;
        overflow: hidden;
        z-index: 1000;

        .tablist {
          @extend %flex-row-jc;
        }

        .tabpanel {
          padding-top: 1rem;
        }

        .img-upload-wrapper {
          input[type='file'] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;

            &:focus-visible + label {
              @extend %focus-visible-style;
            }
          }

          label.upload-btn {
            @extend %flex-row;
            justify-content: space-evenly;
            cursor: pointer;
            font-weight: normal !important;
            font-size: vars.$font-size-xxs !important;

            i {
              font-size: 20px;
            }
          }
        }

        label {
          margin-bottom: 1rem;
          font-size: vars.$font-size-xs !important;

          input[type='text'] {
            margin-top: 0.5rem;
            padding: 0.7rem 1rem;
          }

          textarea {
            &.error {
              border-color: red;
            }
          }

          .error-msg {
            color: vars.$error-color;
          }
        }

        figure {
          min-height: 8rem;
          width: 100%;
          margin: 0 0 1rem 0;
          border: 2px solid vars.$border-soft-color;
          border-radius: 4px;
          background-color: lighten(vars.$border-soft-color, 15%);
          position: relative;

          figcaption {
            @extend %text-break-to-ellipsis;
            padding: 0.5rem;
            text-align: center;
          }

          img {
            @include pres.width-height(100%, auto);
            max-height: 200px;
            object-fit: contain;
            z-index: 10;
          }

          .image-preview {
            @extend %flex-row-jc-ac;
            @extend %absolute-and-fill-space;
            @extend %text-all-caps;
            z-index: 1;
            overflow: hidden;
            color: vars.$pale-text-color;
            user-select: none;
            font-size: vars.$font-size-xxs;
          }
        }

        .tex-container {
          border: 2px solid vars.$border-soft-color;
          border-radius: 4px;
        }

        .modal-btns {
          @extend %flex-row;
          margin-top: 1rem;

          button {
            flex-basis: 50%;

            &:first-child {
              margin-right: 0.2rem;
            }

            &:last-child {
              margin-left: 0.2rem;
            }
          }
        }
      }
    }
  }
}

@include pres.bp-laptop-sm {
  .editor-menu-modal {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    margin-left: -160px;
    margin-top: -160px;
  }
}
