@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

@use 'WizardPages/WizardPages';

.assignment-row {
  color: vars.$navbar-color;
  justify-content: center;
  flex-wrap: wrap;
}

form#initial-assignment-setup {
  fieldset > legend {
    font-weight: bold;
  }
}

.assignment-menu,
.workflows-menu {
  @include pres.restrict-to-view-width(1rem);
  flex: 0 0 auto !important;
  padding: 4rem;
  align-self: center;
  margin: 1rem !important;

  h1 {
    text-align: center;
    font-size: vars.$font-size-sm;
  }

  a.recommended-btn {
    @extend %flex-row-jc;
    @extend %default-btn;
    padding: 3rem 2rem;
    border-radius: 2rem;
    font-size: vars.$font-size-xs;
    color: white;
    background: linear-gradient(-170deg, #2b93d9, #bc2cd8);
    text-align: start;
    position: relative;
    text-decoration: none;

    &:hover {
      background: linear-gradient(-170deg, #2788c9, #a627c0);
    }

    &::after {
      @extend %text-all-caps;
      content: 'Recommended';
      position: absolute;
      bottom: 0.5rem;
      left: calc(50% - 56px);
      font-size: 12px;
      opacity: 0.8;
    }

    span {
      position: relative;

      &::before {
        @extend %text-all-caps;
        content: 'New';
        background-color: white;
        padding: 0.3rem 0.5rem;
        border-radius: 0.5rem;
        font-weight: bold;
        color: vars.$navbar-color;
        position: absolute;
        font-size: 10px !important;
        left: -3.5rem;
        top: 0.1rem;
      }
    }
  }

  p#or {
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
  }

  #tutorial-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.assignment-menu,
.assignment-copy-menu,
.assignment-preset-menu,
.workflows-menu {
  @extend %flex-col-ac;
  margin: 2rem;

  h1 {
    margin-bottom: 4rem;
  }

  a,
  button {
    margin-bottom: 1rem;
  }

  section.button-menu {
    @extend %flex-col;
    align-items: stretch;
    max-width: 100%;
    width: 640px;
  }

  .exit-btn {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}

.workflows-menu {
  padding: 3rem;
  max-width: 640px;

  h1 {
    margin-bottom: 2rem;
  }

  #use-workflow-btn {
    width: 100%;
    padding: 1rem;
  }
}

.assignment-copy-menu,
.assignment-preset-menu {
  overflow: hidden;
  max-width: 100%;
  width: 1256px;
  flex-grow: 0;
  padding: 3rem;

  h1 {
    text-align: center;
    font-size: vars.$font-size-sm;
  }

  h2 {
    font-size: vars.$font-size-xs;
  }

  h1,
  h2 {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0 0.5rem;
    align-self: stretch;
    overflow-y: auto;
  }

  .ctrls {
    align-self: flex-start;

    p {
      margin-top: 0.5rem;
      i#search-query {
        color: vars.$link-text-color;
      }
    }
  }

  #course-list-table {
    align-self: stretch;
  }
}

.assignment-copy-menu {
  li.copy-card {
    .main-info {
      flex: 0 0 40%;
    }

    .course-info {
      flex: 1 0 30%;
      margin: 0 0 0 3rem;

      h4 {
        font-size: vars.$font-size-xs !important;
      }
    }
  }
}

li.assignment-card,
.assignment-ghost {
  @extend %flex-row-ac;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10rem;
  background-color: white;
  list-style-type: none;
  box-shadow: 0 3px rgb(230, 230, 230);

  .assignment-icon {
    @include pres.width-height(3rem);
    margin: 1rem 0;
    border: 2px solid vars.$navbar-color;
    border-radius: 50%;
    font-size: vars.$font-size-sm;
    text-align: center;
    flex-shrink: 0;

    i {
      vertical-align: middle;
    }
  }

  .main-info,
  .assignment-icon {
    margin-right: 1.5rem;
  }

  .course-info {
    margin-left: 3rem;
    margin-right: 2rem;
  }

  .main-info {
    h2,
    h3,
    h4 {
      font-size: vars.$font-size-xs;
    }

    p {
      font-size: vars.$font-size-xxs;
    }
  }

  p {
    color: vars.$pale-text-color;
    margin: 0;
  }

  h2,
  h3,
  h4 {
    font-size: 20px;

    i {
      vertical-align: bottom;
      margin-right: 0.5rem;
    }
  }
}

.assignment-ghost {
  height: 7rem;
  border: none;
}

#initial-assignment-setup-container {
  margin: 4rem;
  width: auto !important;
  max-width: 720px;
  padding: 4rem 5rem;
  border-radius: 4rem;

  .peer-form-page {
    pointer-events: inherit !important;
  }

  .peer-form-header {
    h1 {
      margin: 0 !important;
    }
  }

  .peer-form-body {
    padding: 1rem 4rem 0 4rem;
    align-items: flex-start;
    min-height: 30vh;
  }

  legend {
    margin-bottom: 1rem;
  }

  .settings-visual {
    @extend %flex-col-ac;
    width: 100%;

    .icon-label-wrapper {
      margin-bottom: 0.5rem;

      span {
        display: block;
        text-align: center;
        font-size: vars.$font-size-xxs;
        line-height: vars.$font-size-xxs;
      }
    }
  }
}

form.wizard {
  width: 1256px;

  .content {
    flex-grow: 1;
  }
}

#publish-prompt {
  @include pres.panel-padding-radius(4rem, 2rem);
  align-self: center;

  h1 {
    text-align: center;
  }

  .text-body {
    margin: 2rem 0;
  }

  .ctrls {
    @extend %flex-row-jc;
    flex-wrap: wrap;

    button {
      margin: 0.25rem;
    }
  }
}

#assignment-tutorial {
  max-width: 480px;

  .heading-wrapper {
    @extend %flex-row-jc;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
      margin: 0 0 0 1rem;
    }
  }

  p {
    margin: 0 0 1rem 0;
    font-size: vars.$font-size-xs;
  }

  .page-ctrls {
    @extend %flex-row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;
  }

  figure {
    img {
      margin: 0 auto;
      display: block;
      height: 100px;
    }

    figcaption {
      color: vars.$pale-text-color;
      margin: 0;
      text-align: center;
    }
  }

  ul#phase-list {
    padding: 0;
    width: 180px;
    margin: 0 auto;

    li {
      vertical-align: middle;
      margin-bottom: 1rem;
      list-style-type: none;

      i,
      b {
        vertical-align: middle;
      }

      b {
        margin-left: 0.5rem;
      }
    }
  }
}

.workflow-radio-wrapper {
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  input[type='radio'] {
    @include pres.width-height(100%);
    opacity: 0;
    position: absolute;
    cursor: pointer;

    &:hover + label {
      border-color: lighten(vars.$primary-text-color, 40%) !important;
      box-shadow: 0 0 2px 2px lighten(vars.$primary-text-color, 40%);
    }

    &:focus-visible + label {
      @extend %focus-visible-style;
    }

    &:checked {
      + label {
        background-color: vars.$primary-text-color;
        border-color: vars.$primary-text-color;
        transition: background-color 0.1s;
        color: white;

        &:hover {
          border-color: vars.$primary-text-color !important;
        }
      }
    }
  }

  label {
    display: block;
    color: vars.$primary-text-color;
    border: 1px solid vars.$primary-text-color;
    border-radius: 5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    background-color: transparent;
    transition: background-color 0.1s, box-shadow 0.1s;
    margin: 0;
    user-select: none;

    &:hover {
      border-color: darken(vars.$primary-text-color, 5%);
    }
  }
}

.assignment-diagram {
  figure {
    img {
      margin: 0 auto;
      display: block;
    }

    figcaption {
      color: vars.$pale-text-color;
      margin: 0;
      text-align: center;
    }
  }
}

.course-copy-list {
  @extend %flex-row-jc;
  flex-wrap: wrap;
  overflow-y: auto;
}

#review-management {
  @extend %flex-col-jc-ac;
  padding: 1rem;

  h1 {
    font-size: vars.$font-size-sm;
  }

  p {
    align-self: auto !important;
    max-width: 480px !important;
  }

  i.success-icon {
    color: vars.$success-color;
  }

  #existing-groups,
  form#group-upload-menu,
  #confirmation,
  #deanonymize-list,
  #assign-review-options,
  #copy-assigned-reviews,
  #delete-assigned-reviews {
    @extend %flex-col-ac;
    @include pres.panel-padding-radius(1rem 2rem, 2rem);
  }

  #existing-groups,
  form#group-upload-menu,
  #deanonymize-list,
  #assign-review-options,
  #copy-assigned-reviews,
  #delete-assigned-reviews {
    position: relative;

    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  #assign-review-options {
    #btn-in-column {
      @extend %flex-col;
      button {
        margin-bottom: 0.25rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  ul.deanonymize-list {
    padding-left: 5rem;
    padding-right: 4rem;

    list-style-type: none;

    .submitter-name-btn {
      @extend %flex-row;
      padding-bottom: 0.5rem;
      justify-content: space-between;

      .submitter-name {
        margin-right: 2rem;
      }
    }
  }

  form#group-upload-menu {
    align-self: auto !important;

    .alert-bar {
      margin-bottom: 1rem;
    }
  }

  #existing-groups,
  #confirmation {
    ul {
      padding-left: 5rem;
      max-height: 30vh;
      padding-right: 1rem;
      overflow-y: auto;

      & > li span {
        background-color: vars.$primary-color-2;
        color: white;
      }

      ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
        overflow: visible;
      }
    }

    ul,
    ol {
      list-style-type: none;

      li {
        position: relative;
        vertical-align: middle;
      }

      span {
        position: absolute;
        right: 100%;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 0.125rem;
        background-color: rgb(236, 236, 236);
        color: rgb(97, 97, 97);
        border-radius: 0.25rem;
        margin-right: 0.5rem;
      }
    }

    .peer-dropdown {
      .button-mini {
        padding: 0.25rem;

        i {
          font-size: 20px !important;
        }
      }

      ul.dropdown-list {
        padding: 0.5rem;

        li {
          white-space: nowrap;
        }
      }
    }
  }
}

#assignment-libary {
  @extend %flex-col-ac;
}

#review-users-list-table {
  td {
    ol {
      list-style-type: decimal !important;
      padding-left: 1rem;
      margin: 0;
      text-align: left;
    }

    span.rand {
      color: vars.$pale-text-color;
    }
  }
}

#assign-reviews-to-student-menu {
  @extend %flex-col-ac;

  select {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }

  pre {
    margin-bottom: 1rem;
  }

  .nice-table {
    td:first-child {
      text-align: left;
    }
    td:last-child {
      text-align: center;
    }

    input[type='checkbox'] {
      cursor: pointer;
    }
  }

  .alert-bar {
    margin: 1rem 0;
  }
}

#delete-assigned-reviews {
  max-width: 560px;

  .alert-bar {
    margin-bottom: 1rem;
  }

  .input-wrapper {
    label,
    input {
      cursor: pointer;
    }

    label {
      margin-right: 0.5rem;
    }
  }
}

@include pres.bp-laptop-sm {
  .assignment-menu {
    padding: 4rem !important;
  }
}

@include pres.bp-tablet-lg {
  li.assignment-card {
    .course-info {
      margin: 0 0 0 2rem !important;
    }
  }
}

@include pres.bp-tablet-sm {
  #initial-assignment-setup-container {
    margin: 2rem !important;

    .peer-form-header,
    .peer-form-body {
      padding: 0 !important;
    }
  }

  .assignment-copy-menu,
  .assignment-preset-menu {
    padding: 2rem !important;
  }

  li.assignment-card {
    .main-info {
      h2 {
        font-size: vars.$font-size-xs !important;
      }
    }

    .course-info {
      margin: 1rem 0 0 0 !important;

      h4 {
        text-align: center;

        i {
          font-size: 20px !important;
        }
      }
    }

    span.extra-text {
      display: none;
    }
  }

  li.copy-card {
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 2rem;
    padding: 1rem !important;

    .assignment-icon {
      flex: 0 0 auto !important;
    }

    .main-info {
      flex: 0 0 70% !important;
      margin: 0;
    }

    .course-info {
      flex: 1 0 100% !important;
    }
  }
}

@include pres.bp-mobile-lg {
  .assignment-menu {
    padding: 2rem !important;
  }

  #initial-assignment-setup-container {
    padding: 2rem !important;

    .peer-form-header h1 {
      text-align: center !important;
    }

    .peer-form-body {
      align-items: center !important;
    }
  }

  form.wizard {
    margin: 0.5rem !important;

    h1,
    h2 {
      font-size: vars.$font-size-sm !important;
    }

    h3,
    label,
    li,
    legend {
      font-size: vars.$font-size-xs !important;
    }
  }

  li.assignment-card {
    padding: 0.5rem 1.5rem;

    .assignment-icon {
      width: 2rem !important;
      height: 2rem !important;

      i {
        font-size: vars.$font-size-xs !important;
        vertical-align: 0.4rem;
      }
    }
  }
}
