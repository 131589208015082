@use 'presets' as pres;
@use 'variables' as vars;

*,
:after,
:before {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
}

body {
  background-color: vars.$background-color;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
  padding: 0 0.25rem;
  margin: 0;
  background-color: rgb(245, 245, 245);
  border-radius: 0.25rem;
  border: 1px solid vars.$border-soft-color;
}

table {
  border-collapse: collapse;

  th,
  td {
    padding: 1em;
    border: 1px solid vars.$border-soft-color;
  }
}

button {
  i {
    pointer-events: none;
  }
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled),
[role='button'] {
  cursor: pointer;
}

a {
  color: #0071f0;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// TODO: take custom classes out and have them extend from here
textarea,
input[type='text'],
input[type='url'],
input[type='number'],
input[type='email'],
input[type='password'],
.tags-input,
a.link-view {
  border-radius: 1em;
  padding: 1em;
  border: 1px solid vars.$border-soft-color;
  background-color: vars.$off-white;
  overflow: auto;
  transition: box-shadow 0.2s, border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #7b7b7b;
    box-shadow: inset 0 0 1em 2px #bebebe34;
  }
}

textarea {
  width: 100%;
  height: 6em;
  min-height: calc(4em);
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input[type='url'],
input[type='number'],
a.link-view {
  border-radius: 10em;
}

fieldset {
  @extend %flex-col-ac;
  position: relative;
  margin: 0 0 2em;
  min-width: 0;
  padding: 0;
  border: 0;

  legend {
    font-size: vars.$font-size-xs !important;
    margin-bottom: 0.5rem;
    padding: 0;

    p {
      margin: 0;
    }
  }
}

blockquote,
blockquote.left,
blockquote.right {
  margin: 0 0 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: lighten(vars.$secondary-color, 40%);
  position: relative;
  animation: fadeInUp 0.5s both;

  &::after {
    @include pres.width-height(0);
    content: '';
    position: absolute;
    left: -0.8rem;
    top: calc(100% - 1.25rem);
    border-bottom: 1rem solid lighten(vars.$secondary-color, 40%);
    border-left: 1rem solid transparent;
    transform: rotate(-10deg);
  }
}

blockquote.left {
  margin-right: 2rem;
}

blockquote.right {
  margin-left: 2rem;
  background-color: lighten(vars.$primary-color, 40%);

  &::after {
    left: auto;
    right: -0.8rem;
    border-bottom: 1rem solid lighten(vars.$primary-color, 40%);
    border-right: 1rem solid transparent;
    border-left: none;
    transform: rotate(10deg);
  }
}

select {
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-inline: 0.5ch;
  font-weight: bold;
  border-color: vars.$border-soft-color;

  option {
    border-radius: 0.5rem;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.aria-hides {
  &[aria-hidden='true'] {
    display: none;
  }

  &[aria-hidden='false'] {
    display: flex;
  }
}

[hidden] {
  display: none !important;
}

/*
  Non-base HTML:
*/

.page {
  display: flex;
  min-height: 100%;

  .col {
    // Bootstrap remnant
    display: flex !important;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    justify-content: center !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

#site-container {
  @extend %flex-row;
  height: 100vh;

  #site-content {
    flex-grow: 1;
    overflow: auto;

    & > .tabs {
      height: 100%;
    }
  }
}

.panel,
.panel-sm,
.panel-border,
.panel-justify-center,
.panel-white {
  @include pres.panel-padding-radius(4rem, 2rem);
  display: flex;
  flex: 1 0 auto;
  position: relative;
  transition: opacity 1s;

  h1.title,
  h2.title {
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d8d8d8;
  }
}

.panel-sm {
  padding: 2em;
}

.panel-border {
  border: 1px solid vars.$border-soft-color;
}

.panel-justify-center {
  justify-content: center;
}

.panel-white {
  background-color: white;
}

.button-mini {
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 0.5rem;

  i,
  svg {
    fill: vars.$icon-color;
    color: vars.$icon-color;
    vertical-align: middle;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.neumorphic {
  box-shadow: 0px 12px 16px 0 rgba(0, 0, 0, 0.25), 0px -12px 12px 0 rgba(255, 255, 255, 0.575);
}

.neumorphic-lite {
  box-shadow: 0px 6px 8px 0 rgba(0, 0, 0, 0.25), 0px -6px 6px 0 rgba(255, 255, 255, 0.575);
}

.soft-shadow {
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.123);
}

.no-scroll {
  overflow: hidden;
}

.no-children {
  * {
    display: none;
  }
}

// Screen-readers only
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.sr-show-on-focus:focus-visible {
  left: 0 !important;
  top: 0 !important;
  width: auto !important;
  height: auto !important;
  z-index: 10000;
}

.link-input-wrapper {
  background-color: white;
  border-radius: 10rem;
  padding: 1rem;
  width: 30rem;
  margin-bottom: 2rem;

  i {
    color: vars.$navbar-color;
    font-size: vars.$icon-size;
    margin-right: 0.5rem;
  }

  input {
    flex-grow: 1;
  }
}

fieldset {
  &.row-inputs {
    flex-direction: row !important;

    .rad-radio-btn {
      display: inline-block;
    }
  }

  &.col-inputs {
    flex-direction: column !important;
    align-items: stretch;

    .rad-radio-btn {
      margin: 0 0 0.5rem 0;

      label {
        text-align: center;
        width: 100%;
      }
    }
  }
}

mark {
  background-color: #aed9ff !important;
}

.rad-radio-btn {
  position: relative;
  margin-right: 0.5rem;

  &:last-child {
    margin: 0;
  }

  input[type='checkbox'],
  input[type='radio'] {
    @include pres.width-height(100%);
    opacity: 0;
    position: absolute;
    cursor: pointer;

    + label {
      display: inline-block;
      width: 100%;
      font-weight: normal !important;
      border: 2px solid #e9e9e9;
      border-radius: 10rem;
      padding: 0.75rem 2rem;
      margin: 0;
      text-align: center;
    }

    &:hover + label {
      border-color: darken(#e9e9e9, 5%);
      background-color: #f6f6f6;
    }

    &:focus-visible + label {
      @extend %focus-visible-style;
    }

    &:checked {
      + label {
        border-color: #29ccff;
        transition: border-color 0.1s;

        &:hover {
          border-color: vars.$primary-text-color !important;
        }
      }
    }
  }
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-between {
  justify-content: space-between !important;
}

.align-center {
  align-items: center !important;
}

.flex-half {
  flex-basis: 50% !important;
}

.font-sm {
  font-size: vars.$font-size-sm !important;
}

.warning {
  color: vars.$error-color !important;
}

.fadeIn {
  animation: fadeIn 0.5s;
}

.hidden {
  display: none;
}

.col-reverse {
  flex-direction: column-reverse !important;
}

button.peer-btn-low,
a.peer-btn-low {
  @extend %default-btn;
  text-align: center;
  background-color: vars.$primary-color-2;
  color: white;
  padding: 1rem;
  border-radius: 10rem;
  font-weight: bold;

  &:hover {
    background-color: lighten(vars.$primary-color-2, 5%);
  }
}

button.circ-btn,
a.circ-btn {
  @extend %default-btn;
  @include pres.width-height(3rem);
  display: inline-block !important;
  background-color: vars.$primary-color-2;
  color: white !important;
  border-radius: 50%;
  text-decoration: none;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 3rem;

  &:hover {
    background-color: lighten(vars.$primary-color-2, 5%);
  }

  i {
    vertical-align: middle;
  }
}

table.formatting-table {
  td {
    border: none;
  }
}

table.nice-table {
  @extend %table-no-borders;

  th,
  td {
    padding: 0.5rem 1rem;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  th {
    text-align: left;
  }

  td {
    text-align: right;
  }

  tr:nth-child(odd) {
    background-color: rgb(245, 245, 245);
  }
}

.with-icon {
  vertical-align: middle;

  i {
    vertical-align: middle;
  }
}

[data-tippy-root] {
  width: max-content;
}

.home-page {
  nav.mininav {
    max-width: 1200px;
    background-color: lighten(vars.$background-color, 3%) !important;
    box-shadow: none !important;
    margin: 0 !important;

    a.selected {
      background-color: vars.$background-color !important;
    }
  }

  .ctrls-row {
    @extend %flex-row;
    width: 100%;
    max-width: 1200px;
    margin: 1rem 0;
    padding: 0 0.5rem;
    flex-wrap: wrap;

    .col-2,
    .col-3 {
      @extend %flex-row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      &:first-child {
        justify-content: flex-start !important;
      }

      &:last-child {
        justify-content: flex-end !important;
      }

      .peer-button {
        vertical-align: middle;

        i {
          vertical-align: middle;
          font-size: 16px;
        }
      }

      .peer-dropdown button {
        i {
          font-size: 20px !important;
        }
      }

      .searchbar {
        flex-shrink: 1;
      }

      .entries-select-wrapper {
        flex-shrink: 0;
      }
    }

    .col-2 {
      flex: 1 0 50%;
    }

    .col-3 {
      flex: 1 0 33.33%;
    }
  }

  .home-body {
    width: 100%;
    max-width: 1200px;
  }
}

.pin-drop {
  @extend %default-btn;
  height: 32px;
  min-width: 32px;
  vertical-align: middle;
  font-size: 12px !important;
  color: white;
  font-weight: bold;
  background-color: #df830a;
  text-align: center;
  vertical-align: middle;
  padding: 5px;
  border-radius: 1rem;

  &:disabled {
    cursor: pointer !important;
  }
}

.no-animations {
  *,
  *::before,
  *::after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation: none !important;
  }
}

@include pres.bp-laptop-sm {
  #site-container {
    flex-direction: column !important;
    align-items: stretch !important;

    #site-content.with-sidebar {
      margin-bottom: vars.$tab-bar-height;
    }
  }

  .home-page {
    .ctrls-row {
      flex-direction: column;
      align-items: center;

      .col-2,
      .col-3 {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

@include pres.bp-tablet-sm {
  .panel {
    padding: 3em;
  }
}

@include pres.bp-mobile-lg {
  h1 {
    font-size: vars.$font-size-sm !important;
  }

  h2 {
    font-size: vars.$font-size-xs !important;
  }

  legend {
    text-align: center;
  }

  .panel {
    padding: 2rem;
  }

  .home-page {
    .ctrls-row {
      .col-2,
      .col-3 {
        button {
          letter-spacing: 0px !important;
        }
      }
    }
  }
}
