@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.peer-table-wrapper {
  @extend %flex-col;
  max-width: 100%;
  flex-grow: 0 !important;

  h1,
  h2,
  h3 {
    font-size: vars.$font-size-sm;
  }

  .table-ctrls-top {
    @extend %flex-row-ac;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .table-scrollable-wrapper {
    overflow-x: auto;

    table.peer-table {
      min-width: 100%;
      font-size: vars.$font-size-xxs;

      .table-header-wrapper {
        i.header-icon {
          display: none;
        }

        span {
          max-width: 8rem;
          word-wrap: break-word;
          text-align: left;
        }
      }

      th {
        .table-header-wrapper {
          @extend %flex-row-jc-ac;

          i.header-icon {
            font-size: vars.$font-size-xs;
            color: #81909a;
            margin-right: 0.25rem;
          }

          span.sort-arrows {
            display: inline-flex;
            flex-direction: column;
            pointer-events: none;

            i {
              transform: rotate(-90deg);
              transition: color 0.2s;
              user-select: none;

              &:first-child {
                margin-bottom: -0.4rem;
              }

              &:last-child {
                margin-top: -0.4rem;
              }
            }
          }

          span.sort-arrows:focus {
            position: static !important;
            left: auto !important;
            width: auto !important;
            height: auto !important;
            overflow: visible !important;
            opacity: 0.5;
          }

          span.descending > i {
            transform: rotate(90deg);
          }
        }
      }

      th,
      td {
        padding: 0.5rem;
        border: none;
      }

      td {
        border-bottom: 1px solid #e2e2e2;
        text-align: center;

        .data-unit {
          font-size: 12px;
        }

        i {
          font-size: 20px;
          margin: 0 0 0.25rem 0.25rem;
          vertical-align: middle;
        }
      }

      thead {
        background-color: #f0f0f0;

        tr:first-child > th {
          &:first-child {
            border-top-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
          }
        }

        tr:last-child > th {
          &:first-child {
            border-bottom-left-radius: 0.125rem;
          }

          &:last-child {
            border-bottom-right-radius: 0.125rem;
          }
        }

        tr:nth-child(2) {
          th,
          td {
            padding-top: 0rem;
          }
        }
      }

      tbody {
        tr {
          cursor: pointer;

          &:hover {
            td {
              background-color: #f0f0f0;
            }

            td:nth-child(even) {
              background-color: #e9e9e9;
            }
          }

          td {
            span {
              pointer-events: none;
              touch-action: none;
            }
          }

          td:nth-child(even) {
            background-color: #f7f7f7;
          }
        }

        tr.selected-row {
          background-color: darken(vars.$white, 2%);

          td {
            border: none !important;
          }
        }

        tr.row-more-content {
          transition: height 0.2s;
          background-color: vars.$white;
          cursor: auto;

          td {
            padding: 0;
          }
        }
      }

      th.left-align {
        .table-header-wrapper {
          justify-content: flex-start;
        }
      }

      td.left-align {
        text-align: left;
      }

      th.long-value,
      td.long-value {
        max-width: 320px;
      }
    }
  }

  .pagination-wrapper {
    @extend %flex-row-jc-ac;
    padding: 1rem 0;
    flex-wrap: wrap;
  }

  .table-ctrls-bottom-left,
  .table-ctrls-bottom-right {
    position: absolute;
    bottom: -1rem;
  }

  .table-ctrls-bottom-left {
    left: 2rem;
  }

  .table-ctrls-bottom-right {
    right: 2rem;
  }
}

.table-alt-rows {
  table tbody tr {
    td {
      border: none !important;
    }

    &:nth-child(odd) td {
      background-color: rgb(255, 255, 255) !important;
    }

    &:nth-child(even) td {
      background-color: rgb(246, 246, 246) !important;
    }

    &:hover td {
      background-color: rgb(237, 237, 237) !important;
    }
  }
}

@include pres.bp-tablet-sm {
  .table-ctrls-top {
    flex-direction: column !important;
    align-items: center !important;
    padding: 0 !important;

    .entries-select-wrapper {
      margin-top: 1rem;
    }
  }

  .pagination-wrapper {
    flex-direction: column !important;
    align-content: center !important;

    .peer-pagination {
      margin-bottom: 1rem !important;
    }
  }
}
