@use '../../styles/presets' as pres;
@use '../../styles/variables' as vars;

#rubric-row {
  flex-direction: column;
  flex-wrap: nowrap !important;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  #empty-interface {
    color: darken(vars.$pale-text-color, 5%);
    border: 1px solid darken(vars.$border-soft-color, 5%);
    padding: 3rem 2rem 4rem 2rem;
    border-radius: 2rem;
    align-self: center;
    margin: 2rem 0;

    h1 {
      text-align: center;
      font-size: vars.$font-size-sm;
    }

    p {
      line-height: 2rem;
      font-size: vars.$font-size-xs;
    }

    .choice-wrapper {
      text-align: center;

      .or {
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      #import-btn {
        width: calc(100% - 2rem);
        margin: 0 1rem;
      }
    }
  }
}

#rubric-builder-interface,
#prompt-copy-list {
  max-width: 100%;
  padding: 1rem 1rem 12rem 1rem;

  .rubric-container,
  .copy-list-wrapper {
    display: inline-block;
    min-width: 320px;
    max-width: 100%;
  }
}

#comment-prompt-editor,
#rating-prompt-editor {
  flex-direction: column;
  padding: 2rem;
  align-items: center;

  form {
    width: 520px;
  }

  h1 {
    font-size: vars.$font-size-sm !important;
  }

  label {
    font-weight: bold;
  }

  .header-wrapper,
  input#comment-name,
  input#rating-name,
  .rich-editor,
  .list-editor,
  .tags-input,
  #weight-wrapper {
    margin-bottom: 2rem;
  }

  .delete-btn {
    i {
      color: vars.$error-color;
      vertical-align: baseline;
    }
  }
}

#comment-prompt-editor {
  label#req-comments-lbl,
  label#opt-comments-lbl {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  input#req-comments,
  input#opt-comments {
    margin: 0 0 1rem 1rem;
    text-align: center;
  }
}

#rating-prompt-editor {
  label#rating-weight-lbl {
    margin-right: 1rem;
  }

  input#rating-weight {
    width: 6rem;
  }

  #help-resource-btn-wrapper {
    span {
      margin-left: 0.5rem;
    }
  }

  table#resource-table {
    td {
      vertical-align: middle;

      a,
      button {
        vertical-align: middle;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    button i {
      color: vars.$cancel-color;
    }
  }
}

#prompt-copy-list {
  flex-direction: column;
  align-items: center;

  .heading-wrapper {
    .exit-btn {
      margin-right: 1rem;
    }

    h1 {
      display: inline;
      vertical-align: middle;
    }
  }
}

#resource-menu {
  @extend %flex-col-ac;
  align-self: stretch;

  .tablist {
    @extend %flex-row-jc;
  }

  .modal-tab {
    padding: 1rem;
  }

  p,
  label,
  input[type='number'],
  input[type='text'] {
    margin: 0 0 1rem 0 !important;
  }

  input[type='file'] {
    @include pres.width-height(1px);
    opacity: 0;
    overflow: hidden;
    z-index: -1;

    & + label {
      cursor: pointer;
    }
  }

  input[type='number'] {
    max-width: 8rem;
    text-align: center;
  }

  .link-input-wrapper {
    max-width: 300px;
    padding: 0;
  }

  #threshold-wrapper {
    label {
      margin-right: 1rem !important;
    }
  }
}

#add-prompt-options-menu {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  a {
    margin-bottom: 0.5rem;
  }
}

.read-only-rubric {
  p#no-edit-label {
    text-align: center;
  }

  .prompt-display {
    padding: 1rem 2rem 0 2rem !important;
  }
}

.prompt-display {
  @include pres.panel-padding-radius(1rem 3rem 0 2rem, 1rem);
  margin: 0.5rem 0 0.5rem 2rem;
  position: relative;
  max-width: 512px;

  i.icon {
    position: absolute;
    top: 0;
    padding: 0.6rem;
    user-select: none;
  }

  i.icon {
    right: calc(100% - 1rem);
    background-color: vars.$primary-color-2;
    color: white;
    border-radius: 50%;
  }

  .ctrls {
    @extend %flex-col-ac;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 !important;

    button.edit-btn,
    button.select-btn {
      @extend %default-btn;
      background-color: white;
      width: 3rem;
      padding: 0.5rem;
      border-radius: 0.75rem;
      line-height: 1rem;
      color: vars.$pale-text-color;

      &:hover {
        background-color: rgb(249, 249, 249);
        color: black;
      }

      span {
        letter-spacing: 2px;
        font-size: 10px;
        text-transform: uppercase;
        text-align: center;
      }
    }

    button.select-btn {
      span {
        letter-spacing: 0;
      }
    }

    .drag-handle,
    .button-mini {
      padding: 0;
      color: vars.$pale-text-color;
      line-height: normal;
    }
  }

  h2.prompt-name {
    font-size: vars.$font-size-xs;
    overflow-wrap: break-word;

    span.title {
      margin-right: 1rem;
    }

    span.badge {
      font-size: vars.$font-size-xxs;
      box-shadow: 0 0 0 2px lighten(vars.$primary-color-2, 25);
      color: vars.$pale-text-color;
      padding: 0.2rem 0.6rem;
      border-radius: 2rem;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  .body {
    font-size: vars.$font-size-xxs;

    &.less {
      .description-wrapper {
        max-height: 4rem;
        overflow: hidden;
      }

      table td {
        @extend %text-break-to-ellipsis;
      }

      .hidden-elements {
        display: none;
      }
    }

    .description-wrapper {
      margin-bottom: 0.5rem;
    }

    button.desc-more,
    button.desc-less {
      padding: 0.25rem;
      margin: -0.5rem 0 0 -0.25rem;
    }

    button.desc-more {
      font-family: 'Material Icons';
      font-size: 20px;
      line-height: 20px;
    }

    button.desc-less {
      padding: 0.5rem;
      font-weight: bold;
      color: vars.$pale-text-color;
    }

    table {
      @extend %table-no-borders;
      margin-bottom: 1rem;

      &,
      tbody,
      tr {
        display: block;
        max-width: 100%;
      }

      th,
      td {
        display: inline-block;
        padding: 0.125rem;
      }

      th {
        width: 2rem;
        vertical-align: top;
        letter-spacing: 1px;
      }

      td {
        width: calc(100% - 2rem);
      }

      tr:nth-child(even) {
        background-color: rgb(245, 245, 245);
      }
    }

    .tags {
      span {
        display: inline-block;
        background-color: rgb(246, 246, 246);
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        margin: 0 0.25rem 0.5rem 0;
        color: vars.$pale-text-color;
        border: 1px solid vars.$border-soft-color;
      }
    }
  }

  button.toggle-body-size {
    @extend %default-btn;
    width: 100%;
    padding: 0.5rem;
    color: vars.$pale-text-color;
    font-weight: bold;
    border-radius: 0 !important;
    font-size: vars.$font-size-xxs;
    vertical-align: middle;

    &.more {
      position: relative;
    }

    i {
      vertical-align: middle;
      font-size: inherit;
    }
  }
}

#rubric-library {
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 4rem;
  min-height: calc(100% - 48px);

  .main-ctrls {
    @extend %flex-row-ac;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0;

    @include pres.bp-height {
      position: relative !important;
    }

    h1 {
      font-size: vars.$font-size-sm;
      margin: 0 1rem;
    }

    .input-wrapper {
      display: inline-flex;
      align-items: stretch;

      .searchbar {
        input {
          border-radius: 10rem;
        }
      }

      a,
      button {
        margin-left: 0.5rem;
      }
    }

    #return-btn {
      position: absolute;
      width: 100%;
      top: calc(100% + 1rem);
      right: 0;
    }

    .search-wrapper {
      @extend %flex-row;

      .searchbar {
        margin-right: 0.5rem;
      }
    }
  }

  #prompts-container {
    flex-grow: 1;
    width: 100%;
    max-width: 1200px;
    min-height: 25vw;
    overflow: hidden;
    position: relative;

    .prompt-scroller {
      @extend %absolute-and-fill-space;
    }
  }

  section.prompt-page-main {
    @extend %flex-col-ac;
    width: 100%;
    background-color: #e0e4f1;
    padding: 1rem;

    .main-wrapper {
      @extend %flex-col;

      a {
        margin-bottom: 0.5rem;
      }
    }
  }

  #copy-return-btn {
    margin: 1rem;
    width: 100%;
    max-width: 1200px;
  }

  .query-table .options-cell {
    padding: 0;
  }
}

#rubric-editor-page {
  flex-direction: column;
  align-items: center;
  position: relative;

  .banner {
    @include pres.panel-padding-radius(1rem, 0 0 1rem 1rem);
    position: sticky;
    top: 0;
    z-index: 56;
    margin-bottom: 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    width: 100%;
    max-width: 864px;

    @include pres.bp-height {
      position: relative !important;
    }

    input#editable-rubric-name {
      background-color: transparent;
      border: none;
      padding: 0 0.25rem;
      margin-bottom: 0.25rem;
      width: 100%;
      border-radius: 0;
      font-size: vars.$font-size-sm;

      &:not(:focus) {
        @extend %text-break-to-ellipsis;
        font-weight: bold;
      }
    }

    .details-row {
      @extend %flex-row-ac;
      justify-content: space-between;
      vertical-align: middle;

      .main-section {
        @extend %flex-row-ac;
        flex-wrap: wrap;
        margin-right: 2rem;
      }

      .favorite-btn {
        @extend %default-btn;
        vertical-align: middle;
        border-radius: 1rem;

        &:hover {
          background-color: #eaeaea;
        }
      }

      span#visibility-toggle {
        position: relative;
        margin: 0 0.5rem;
        padding: 0;

        &[aria-disabled='true'] {
          &:hover {
            background-color: transparent !important;
          }
        }

        label {
          margin: 0;
          border-radius: 10rem;
          padding: 0.25rem;
        }

        input[type='checkbox'] {
          @extend %absolute-and-fill-space;
          opacity: 0;
          cursor: pointer;
        }

        input:focus-visible + label {
          @extend %focus-visible-style;
          background-color: rgb(228, 228, 228);
        }

        input:disabled + label {
          i {
            color: rgb(181, 181, 181) !important;
          }
        }
      }

      span#num-prompts {
        font-weight: bold;
        margin: 1rem;
      }
    }

    #import-btn {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: 100%;
    }
  }

  .banner.with-import {
    margin-bottom: 4rem;
  }

  #prompts-container {
    .prompt-display {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  #rubric-hidden-text {
    flex-direction: column;
    flex-grow: 0;
    margin: auto 0;

    h1 {
      margin-bottom: 2rem;
      text-align: center;
    }

    p {
      font-size: vars.$font-size-xs;
    }
  }
}

#assignment-rubric-catalog {
  justify-content: center;
  align-items: center;

  #menu {
    @include pres.panel-padding-radius(1rem, 1rem);

    h1 {
      font-size: vars.$font-size-sm;
      text-align: center;
      margin-bottom: 1rem;
    }

    .catalog-item {
      @extend %flex-row;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 1rem;
      border-bottom: 1px solid vars.$border-soft-color;
      margin-bottom: 1rem;

      &:last-child {
        border-bottom: none;
        margin: 0;
      }

      .details {
        flex-grow: 0;
        margin-right: 1rem;

        h2 {
          font-size: vars.$font-size-xs;
        }

        p {
          max-width: 320px;
          margin: 0;
        }
      }

      a {
        flex: 0 0 auto;
      }
    }
  }
}

#tag-library {
  .table-wrapper {
    width: 100%;
    max-width: 1200px;
  }
}

@include pres.bp-tablet-lg {
  #comment-prompt-editor,
  #rating-prompt-editor {
    .comment-prompt-form,
    .rating-prompt-form {
      padding: 2rem;

      form {
        width: auto;
      }
    }
  }
}

@include pres.bp-mobile-lg {
  #rubric-builder-interface,
  #prompt-copy-list,
  #comment-prompt-editor,
  #rating-prompt-editor {
    padding: 1rem 0 12rem 0;

    h1 {
      text-align: center;
    }
  }

  #comment-prompt-editor,
  #rating-prompt-editor {
    .comment-prompt-form,
    .rating-prompt-form {
      padding: 1rem;

      h1 {
        font-size: 20px !important;
      }

      form > .header-wrapper {
        flex-wrap: wrap;
        justify-content: center;

        .title-content {
          flex-basis: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
