@use "../../styles/presets" as pres;
@use "../../styles/variables" as vars;

#admin-dashboard {
  @extend %flex-row-jc-ac;

  #menu {
    flex-direction: column;
    flex-grow: 0;

    h1 i {
      font-size: vars.$font-size-md;
    }

    a {
      margin-bottom: 0.25rem;
    }
  }
}

#admin-purchasing {
  @extend %flex-col-ac;

  h1 {
    margin-top: 1rem;
  }

  .tabs {
    flex-grow: 1;
  }

  .table-wrapper {
    margin: 1rem 0;
    padding: 2rem 2rem 1rem 2rem;
  }

  #purchase-log-tab,
  #special-purchases-tab {
    .ctrls-row {
      margin: 1rem 0;
    }
  }
}

#admin-purchase-modal-content {
  .ctrl-wrapper {
    margin-top: 0.5rem;
  }

  #edit-check-wrapper {
    margin-top: 2rem;
  }

  #delete-check-wrapper {
    label i {
      vertical-align: middle;
    }
  }

  .confirm-wrapper {
    p {
      margin: 0 0 1rem 0;
      text-align: center;
    }

    .ctrls {
      @extend %flex-row-jc;

      button {
        margin: 0 0.25rem;
      }
    }
  }
}

#create-purchase-modal-content,
#update-special-purchase-modal {
  .input-wrapper {
    margin-bottom: 1rem;
  }

  label {
    margin: 0 1rem 0 0;
  }

  input#name {
    width: 100%;
  }

  input#seats,
  input#price {
    width: 6rem;
    text-align: center;
  }
}

#admin-courses {
  @extend %flex-col-ac;

  h1 {
    margin-top: 1rem;
  }

  .ctrls-row {
    @extend %flex-row;
    align-items: baseline;

    .input-wrapper {
      margin: 0.5rem;

      label {
        margin-right: 0.5rem;
      }

      input[type='number'] {
        width: 4rem;
        padding: 0.5rem;
        text-align: center;
      }
    }
  }
}

#user-details-page {
  @extend %flex-col-ac;

  .tabs-wrapper {
    width: 100%;
    max-width: 1200px;
  }

  #details-tab {
    @extend %flex-row-jc;

    .details-card {
      display: inline-flex;
      margin: 1rem 0;
      flex-grow: 0;
      max-width: 100%;
      padding: 1rem;
      overflow: hidden;

      table {
        @extend %table-no-borders;
        max-width: 100%;

        th,
        td {
          padding: 0.5rem;
        }

        th {
          text-align: right;
        }
      }
    }
  }

  #activity-tab {
    #activity-card {
      @include pres.panel-padding-radius(1rem, 1rem);
      margin: 1rem;

      h2 {
        font-size: vars.$font-size-xs;
      }
    }
  }

  #performance-tab {
    #return-btn {
      display: block;
      width: 448px;
      max-width: 100%;
    }

    #return-btn,
    .tracked-performance,
    fieldset {
      margin: 0.5rem auto;
    }
  }
}

table.bar-chart {
  @extend %table-no-borders;
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 1rem 0.25rem;

  th,
  td {
    padding: 0.25rem 0.5rem;
  }

  th.track {
    opacity: 0;
  }

  td.track button {
    @extend %default-btn;
    font-size: vars.$font-size-xxs;

    &:hover {
      text-decoration: underline;
    }

    i {
      font-size: vars.$font-size-xs;
      vertical-align: middle;
    }
  }

  .name {
    @extend %text-break-to-ellipsis;
    text-align: right;
    width: 1px;
    max-width: 160px;
    word-wrap: nowrap;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .score {
    text-align: left;
  }

  td.score {
    border-radius: 0.25rem;
    background-color: #dcdcdc;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .track {
    width: 1px;
    white-space: nowrap;
  }
}

#user-libary {
  @extend %flex-col-ac;
}

.role-selection{
  display: inline-block;
}