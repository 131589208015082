@use '../../../../styles/presets' as pres;
@use '../../../../styles/variables' as vars;

.navbar {
  @extend %flex-row;
  justify-content: space-between;
  align-content: stretch;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9050;
  padding: 0 0.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;
  justify-content: space-between;
  background-color: vars.$navbar-color;
  transition: top 0.3s;

  a {
    color: white !important;
  }

  .navbar-logo {
    @extend %flex-row-ac;
    margin-right: 1rem;
    order: 1;
  }

  .logo-sm {
    display: none;
  }

  .navbar-content {
    @extend %flex-row;
    justify-content: flex-end;
    align-content: stretch;
    flex-grow: 1;
    order: 3;
    min-width: 0;

    .navbar-button {
      @extend %flex-row-ac;
      padding: 0.5rem;
      user-select: none;

      i {
        color: white;
      }

      &:hover:not([aria-disabled='true']) {
        background-color: lighten(vars.$navbar-color, 5%);
      }

      &[aria-disabled='true'] {
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .navbar-button-selected {
      background-color: lighten(vars.$navbar-color, 10%) !important;
    }

    .navbar-course-button {
      @extend %text-break-to-ellipsis;
      min-width: 0;
      display: block;
      background-color: lighten(vars.$navbar-color, 5%) !important;
      border-radius: 10rem;
      color: white;
      margin: 0.5rem;
      padding: 0.5rem 1rem;
      transition: margin 0.1s, padding 0.1s, border-radius 0.1s;

      &:hover {
        background-color: lighten(vars.$navbar-color, 10%) !important;
      }
    }

    .navbar-course-button.navbar-button-selected {
      background-color: lighten(vars.$navbar-color, 10%) !important;
      margin: 0 !important;
      padding: 1rem 1.5rem !important;
      border-radius: 0 !important;
    }

    .navbar-avatar-default {
      @extend %flex-row-jc-ac;
      @include pres.width-height(2rem);
      padding: 0.25rem;
      border-radius: 50%;
      background-color: vars.$secondary-color-2;
      color: white;
      flex: 0 0 auto;
      user-select: none;
    }

    .acting {
      position: relative;

      &::after {
        @include pres.width-height(0.5rem);
        content: '';
        position: absolute;
        right: -0px;
        bottom: -0px;
        background-color: yellow;
        border-radius: 50%;
        animation: border-pulse 2s infinite linear;

        @keyframes border-pulse {
          0% {
            box-shadow: 0 0 0 0px vars.$navbar-color, 0 0 0 0px yellow, 0 0 0 2px vars.$navbar-color;
          }
          10% {
            box-shadow: 0 0 0 0px vars.$navbar-color, 0 0 0 2px yellow, 0 0 0 4px vars.$navbar-color;
          }
          20% {
            box-shadow: 0 0 0 4px vars.$navbar-color, 0 0 0 4px yellow, 0 0 0 4px vars.$navbar-color;
          }
          25% {
            box-shadow: 0 0 0 0px vars.$navbar-color, 0 0 0 0px yellow, 0 0 0 2px vars.$navbar-color;
          }
          100% {
            box-shadow: 0 0 0 0px vars.$navbar-color, 0 0 0 0px yellow, 0 0 0 2px vars.$navbar-color;
          }
        }
      }
    }

    .navbar-menu {
      display: none;
      position: absolute;
      background-color: vars.$navbar-color;
      top: 100%;
      right: 0;
      border-radius: 0 0 1rem 1rem;
      padding: 1rem;
      color: white;
      min-width: 320px;
      max-width: 320px;
      animation: subtle-pop-in 0.1s;
      transform-origin: top right;

      h2 {
        margin-right: 1.5rem;
      }

      hr {
        border-color: #758591;

        &:first-of-type {
          margin-top: 0;
        }
      }

      button.menu-close {
        @include pres.width-height(2rem);
        margin: 1rem 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 !important;

        i {
          color: white !important;
          font-size: 1rem;
        }
      }
    }

    .profile-btn,
    button#end-act-as-btn,
    a#course-list-btn {
      text-transform: none !important;
      font-size: vars.$font-size-xxs;
      flex-basis: 100%;
      vertical-align: middle;

      i {
        vertical-align: middle;
        font-size: 20px;
        margin-right: 0.5rem;
      }
    }

    #profile-menu {
      .profile-info {
        @extend %flex-row-jc-ac;
        flex-wrap: wrap;
        word-break: break-all;

        .avatar-lg {
          @include pres.width-height(4rem);
          font-size: vars.$font-size-md;
          margin: 1rem;
        }

        h2,
        p {
          margin: 0;
        }
      }

      .profile-btn {
        margin-top: 1rem;
      }

      button#end-act-as-btn {
        display: block;
        width: 100%;
        color: yellow !important;
        border-color: yellow !important;
        margin-bottom: 0.5rem;
      }

      .acting-as {
        color: yellow !important;
        text-align: center;

        h2 {
          margin: 0;
        }
      }

      a#logout-btn {
        display: block;
        text-align: center;
        padding: 0.5rem;
        border-radius: 2px;

        i {
          margin-right: 0.5rem;
        }

        span {
          vertical-align: 0.3rem;
        }

        &:hover {
          background-color: lighten(vars.$navbar-color, 5%) !important;
        }
      }
    }

    #course-nav-menu {
      a:hover {
        background-color: lighten(vars.$navbar-color, 5%) !important;
      }

      h3,
      h4 {
        text-align: center;
      }

      h3 {
        font-size: vars.$font-size-xs !important;
      }

      h4 {
        font-weight: normal;
        font-size: vars.$font-size-xxs !important;
      }

      a.course-link {
        @extend %flex-row-ac;
        justify-content: space-between;
        text-align: center;
        padding: 0.5rem;
        border-radius: 2px;

        i.link-icon {
          margin: 0 0.5rem;
        }

        span {
          vertical-align: 0.3rem;
        }
      }

      a#course-list-btn {
        width: 100%;
        margin-top: 1rem;
      }
    }

    #help-menu {
      ul {
        margin: 0;
        padding: 0 0 0 2rem;
        list-style-type: none;

        li {
          margin-bottom: 0.25rem;
          position: relative;

          &::before {
            font-family: 'Material Icons';
            position: absolute;
            right: calc(100% + 0.5rem);
          }
        }

        li.link::before {
          content: 'link';
        }

        li.link:last-child::before {
          content: 'public';
        }

        li.modal-btn::before {
          content: 'article';
        }

        a,
        button {
          text-decoration: underline;

          &:hover {
            color: darken(white, 15%) !important;
          }
        }

        button {
          @extend %default-btn;
          color: white;
          padding: 0;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  button.collapse-btn {
    @extend %default-btn;
    color: white;
    border: 1px solid vars.$border-soft-color;
    border-radius: 0.5rem;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    display: none;
    order: 2;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

#notifications-menu-btn {
  position: relative;

  .notif-num-label {
    position: absolute;
    background-color: #e71313;
    color: white;
    font-size: 10px;
    padding: 1px 3px;
    border-radius: 1rem;
    min-width: 1rem;
    text-align: center;
    font-weight: bold;
    bottom: 6px;
    right: 2px;
  }
}

#notifications-menu,
#notifications-page {
  color: white;

  a:not(.peer-button) {
    color: white !important;
    text-decoration: none;
  }

  .ctrls {
    @extend %flex-row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: -0.5rem;
    font-size: vars.$font-size-xxs !important;

    fieldset {
      input:not(:checked):hover + label {
        color: vars.$navbar-color;
      }
    }
  }

  .notifications-wrapper {
    max-height: calc((100vh - #{vars.$navbar-height}) / 2);

    .notification {
      padding-top: 0.25rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }

    .notification:last-child {
      border: none;
    }

    .no-notifs {
      text-align: center;
      margin: 1rem 0;

      i,
      span {
        margin: 0 0.25rem;
        vertical-align: middle;
        opacity: 0.8;
      }

      i {
        opacity: 0.3;
      }
    }
  }

  button#load-more {
    width: calc(100% - 1rem);
    height: 2rem;
    margin: 0.5rem;
  }

  a#notifs-page-link {
    display: block;
    padding: 0.5rem 0;
    margin-bottom: -0.5rem;
    border-top: 1px solid vars.$border-soft-color;
    text-align: center;

    &:hover {
      text-decoration: underline;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@include pres.bp-laptop-sm {
  .navbar {
    .logo-lg {
      display: none !important;
    }

    .logo-sm {
      display: flex !important;
    }
  }
}
